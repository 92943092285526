import React, { useEffect, useState } from "react";
import {
  BackgroundOverlay,
  FeaturesElement,
  ImageView,
  MetaDecorator,
  PageTopComponent,
  Table,
} from "../components";
import { bloodBank, chart, eTender1, etender2 } from "../assets";
import { bloodBankFeaturesData } from "../utils/bloodBankFeaturesData";
import { changeNavState } from "../reduxStore/navSlice";
import { useDispatch } from "react-redux";
import {
  systemRequirementsA,
  systemRequirementsB,
  technologyUsedA,
} from "../utils/bloodBankData";
import { bloodBanSoftwareCustomer } from "../utils/bloodBankCustomer";
import { Link } from "react-router-dom";

const ETender = ({ homeInfoData }) => {
  const dispatch = useDispatch();

  // Setting the nav state
  useEffect(() => {
    dispatch(changeNavState("products"));
  }, []);

  const [imageToDisplay, setImageToDisplay] = useState("");

  return (
    <div>
      <MetaDecorator
        title="eTender application for Procurement and Tendering"
        titleA="eTender application for Procurement and Tendering"
        keywords="eTender application for Procurement and Tendering"
        description="manage the latest, most complex tenders with ease or simply automate your existing process eTender application use for procurement and tendering process"
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription="manage the latest, most complex tenders with ease or simply automate your existing process eTender application use for procurement and tendering process"
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
      />

      <PageTopComponent
        image={etender2}
        title="e-Tender Application"
        subtitle="eTender Application for Procurement and Tendering"
        description="Onviro Tech developers team developed an e-Tender application around 12 years ago using PHP. We upgraded the latest version of the e-Tender application using React.js front-end and Laravel (latest version 10) back-end system. Our application is used by various NGOs, INGOs to purchase any items or construction work through a tendering process online."
        btnName="Buy e-Tender Software"
        btnLink="/contactus"
      />

      <div className="py-[70px] px-[20px] lg:px-40 flex flex-col gap-[90px]">
        {/*....Technology used....*/}
        <div>
          <p className="text-red font-bold text-3xl pb-4">
            Technology used to develop
          </p>
          <div className="w-full flex gap-[50px]">
            <div className="hidden lg:flex lg:w-[60%] h-[500px] shadow">
              <img
                src={eTender1}
                onClick={() => setImageToDisplay(eTender1)}
                className="w-full h-full object-contain cursor-pointer"
                alt=""
              />
            </div>
            {/*...Image Display mode...*/}
            {imageToDisplay && (
              <div>
                <ImageView
                  image={imageToDisplay}
                  handleCross={() => setImageToDisplay("")}
                />

                <BackgroundOverlay />
              </div>
            )}

            <div className="w-full lg:w-[40%]">
              <Table data={technologyUsedA} bgColor="bg-white" />
            </div>
          </div>
        </div>

        {/*...System Requirements...*/}
        <div>
          <p className="text-red font-bold text-3xl">
            What's procurement software all about?
          </p>
          <p className="pt-3 pb-5">
            e-Tender procurement software is business software that lets
            organizations completely automate their source-to-pay cycle. Users
            can manage every aspect of the procurement process, from budget
            analysis and management to contract management and invoice payment.
            You can use e-Tender procurement software in various ways to
            Purchase any items, Construction works giving your teams better
            visibility and control. All in all, e-Tender procurement software
            can be a fantastic tool for businesses keen on automating the
            processes of buying materials and maintaining a stockpile of goods.
          </p>
          <p className="text-red font-bold text-3xl">
            Types of e-Tender procurement software and tools
          </p>

          <div className="w-full flex gap-[50px] justify-between flex-wrap mt-[40px] ">
            <div className="w-full flex flex-col gap-[10px]">
              <div>
                <p className="w-[70px] h-[70px] rounded-full flex justify-center items-center border border-red text-red font-bold text-xl">
                  {1}
                </p>
              </div>

              <Table data={systemRequirementsA} />
            </div>
          </div>
        </div>

        {/*...System Modules and Features...*/}
        <div>
          <div>
            <p className="text-red font-bold text-3xl">
              Here are some key features to look out for:
            </p>
            <p className="pt-3">
              The eTender procurement software you choose should meet your
              Organization's essential needs. Its capabilities and deployment
              strategies should take into account factors like your
              Organization's size and existing technology stack.
            </p>

            <div className="w-full flex gap-[50px] justify-between flex-wrap mt-[40px] ">
              <div className="w-full flex flex-col gap-[10px]">
                <div>
                  <p className="w-[70px] h-[70px] rounded-full flex justify-center items-center border border-red text-red font-bold text-xl">
                    {2}
                  </p>
                </div>

                <Table data={systemRequirementsB} />
              </div>
            </div>
          </div>
          <div className="mt-8">
            <p className="text-red font-bold text-3xl">
              Why you need e-Tender procurement software?
            </p>
            <p className="pt-3">
              Using procurement software benefits the entire organisation, not
              just your procurement department. Here are some advantages of
              using construction procurement software:
            </p>
            <p className="pt-3 flex gap-2">
              <span>1.</span>
              <p>
                Track your spending with construction procurement software from
                a single, centralised location, giving you visibility into all
                suppliers and transactions.
              </p>
            </p>
            <p className="pt-1 flex gap-2">
              <span>2.</span>
              <p>
                Automation in a procurement platform can boost overall
                operational performance. Standardizing workflows and improving
                process efficiency reduces the time spent on internal procedures
                like tender management and managing contracts and suppliers.
              </p>
            </p>
            <p className="pt-1 flex gap-2">
              <span>3.</span>
              <p>
                Enhanced reporting capabilities in procurement software provide
                you with valuable data to help you make informed decisions.
              </p>
            </p>
            <p className="pt-1 flex gap-2">
              <span>4.</span>
              <p>
                Procurement software offers an efficient collaboration portal,
                which improves both internal and external communication. It
                features centralized storage for all communications and
                documentation.
              </p>
            </p>
            <p className="pt-5 font-semibold">
              By using an automated process, procurement staff can focus on more
              valuable tasks while reducing errors and inefficiencies caused by
              manual processes. if your office/company is willing to run
              e-Tender application kindly drop your lines us at <Link className="font-bold italic text-[21px]" to={`mailto:${'info@onvirotech.com'}`}>info@onvirotech.com,</Link> we’ll contact to you soon!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ETender;
