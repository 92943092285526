import React, { useEffect, useState } from "react";
import { MetaDecorator, PageTopComponent, ProjectCard } from "../components";
import axiosBaseURL, { baseUrl } from "../baseUrl";

const EcommerceProjects = ({homeInfoData}) => {

  const [projectData, setProjectData] = useState([])

  useEffect(() => {
    axiosBaseURL.get(`api/service/website/details`).then((res) => {
        setProjectData(res.data);
    }).catch((err) => console.log(err));
}, []);
const webData = projectData?.Similar_Service;
  return (
    <div>
           <MetaDecorator      
        title="Website Development"
        titleA={projectData?.meta_title ? projectData?.meta_title : ""}
        keywords={projectData?.meta_keywords ? projectData?.meta_keywords : ""}
        description={projectData?.meta_description ? projectData?.meta_description : ""}  
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
        
      <PageTopComponent
        image={projectData?.image}
        title={projectData?.title}
        subtitle={projectData?.summary}
        description={projectData?.description}
      />

      {/*...Projects....*/}
      <div className="py-[70px] px-[20px] lg:px-40">
        <p className="text-red text-center lg:text-center font-bold text-3xl">
          Some websites projects by us
        </p>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-[51px] py-[50px] ">
          {webData?.length > 0 &&
            webData.map((item, index) => (
              <ProjectCard
                key={index}
                companyName={item?.title}
                logo={baseUrl + item?.image}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default EcommerceProjects;
