import React, { useEffect, useState } from "react";
import {
  BackgroundOverlay,
  FeaturesElement,
  ImageView,
  MetaDecorator,
  PageTopComponent,
  Table,
} from "../components";
import { bloodBank, chart } from "../assets";
import { bloodBankFeaturesData } from "../utils/bloodBankFeaturesData";
import { changeNavState } from "../reduxStore/navSlice";
import { useDispatch } from "react-redux";
import { systemRequirements, technologyUsed } from "../utils/bloodBankData";
import { bloodBanSoftwareCustomer } from "../utils/bloodBankCustomer";

const BloodBankSoftware = ({homeInfoData}) => {
  const dispatch = useDispatch();

  // Setting the nav state
  useEffect(() => {
    dispatch(changeNavState("products"));
  }, []);

  const [imageToDisplay, setImageToDisplay] = useState("");

  return (
    <div>
       <MetaDecorator      
        title="Blood Bank Management Software"
        titleA="Blood Bank Management Software"
        keywords="Blood Bank Management Software"
        description="Blood Bank Management Software"  
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />

      
      <PageTopComponent
        image={bloodBank}
        title="Blood Bank Management Software"
        subtitle="Onviro Tech offers efficient Blood Bank Management Software"
        // description="Onviro Tech offers efficient Blood Bank Management Software, providing skilled professionals for diverse projects, contributing to cost-effective solutions and streamlined project execution"
        // btnName="Buy Blood Bank Software"
        btnLink="/contactus"
      />

      <div className="py-[70px] px-[20px] lg:px-40 flex flex-col gap-[90px]">
        {/*....Technology used....*/}
        <div>
          <p className="text-red font-bold text-3xl pb-4">
            Technology used to develop
          </p>
          <div className="w-full flex gap-[50px]">
            <div className="hidden lg:flex lg:w-[60%] h-[500px]">
              <img
                src={chart}
                onClick={() => setImageToDisplay(chart)}
                className="w-full h-full object-contain cursor-pointer"
                alt=""
              />
            </div>
            {/*...Image Display mode...*/}
            {imageToDisplay && (
              <div>
                <ImageView
                  image={imageToDisplay}
                  handleCross={() => setImageToDisplay("")}
                />

                <BackgroundOverlay />
              </div>
            )}

            <div className="w-full lg:w-[40%]">
              <Table data={technologyUsed} bgColor="bg-white" />
            </div>
          </div>
        </div>

        {/*...System Requirements...*/}
        <div>
          <p className="text-red font-bold text-3xl">System Requirements</p>

          <div className="w-full flex gap-[50px] justify-between flex-wrap mt-[40px] ">
            <div className="w-full flex flex-col gap-[10px]">
              <div>
                <p className="w-[70px] h-[70px] rounded-full flex justify-center items-center border border-red text-red font-bold text-xl">
                  {1}
                </p>
              </div>

              <Table data={systemRequirements} />
            </div>
          </div>
        </div>

        {/*...System Modules and Features...*/}
        <div>
          <div className="w-full lg:w-[500px] flex flex-col gap-[30px]">
            <p className="text-red font-bold text-3xl">
              System modules and features
            </p>

            <p className="text-justify">
              Blood bank is not just a blood processing Institution, it also
              Incorporates donor’s data, patient’s data, components data, test
              and results, and other many transactions. So, along with blood
              banking, efficient data putting and retrieving is equally
              Important as well. Moreover, in between these processes, there is
              a lot risk of human errors resulting in fatal outcomes in some
              cases which could really be prevented through use of smart
              software which integrates both human effort and data automation
              effectively.
            </p>
          </div>

          <div className="flex justify-center items-center flex-wrap gap-[30px] mt-[50px]">
            {bloodBankFeaturesData?.length > 0 &&
              bloodBankFeaturesData.map((item, index) => (
                <FeaturesElement key={index} title={item.name} />
              ))}
          </div>
        </div>

        <div className="flex flex-col items-center gap-[30px] mt-[50px]">
          <p className="text-center font-bold text-xl">This software is used by following customers: </p>
          <div className="w-full flex items-center justify-center gap-[40px] flex-wrap">
            {bloodBanSoftwareCustomer?.length > 0 &&
              bloodBanSoftwareCustomer.map((item, index) => (
                <div
                  key={index}
                  className="w-[250px] flex flex-col items-center text-center gap-[10px]"
                >
                  <img src={item.image} alt="" className="w-[250px] h-[70px] object-contain" />
                  <p className="">{item.name}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BloodBankSoftware;
