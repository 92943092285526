import React, {useState} from 'react'
import {BsChevronDown} from "react-icons/bs";
import "../styles/style.css";

const ContactUsDropDown = ({itRequirements, handleCheckBox, checkedId}) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
        <div className='w-full'>
            <div onMouseEnter={
                    () => setIsHovered(true)
                }
                onMouseLeave={
                    () => setIsHovered(false)
                }
                className='relative w-full bg-transparent flex items-center border border-b-dark border-t-0 border-l-0 border-r-0'>
                <input type='text' readOnly value={checkedId?.length > 0 ? checkedId.toString() : ""} placeholder='Choose one or more' className='grow placeholder:text-dark placeholder:opacity-[40%] px-[10px] py-[6px] outline-none bg-transparent '/>
                <BsChevronDown className='text-dark font-bold text-lg'/> {
                isHovered && (
                    <div className='bg-white absolute top-[35px] right-[0px] rounded-[8px] shadow-xl'>
                        {
                        itRequirements ?. map((item, index) => (
                            <div key={index}
                                className={
                                    `flex gap-[5px] p-[10px] border border-b-dark border-t-0 border-l-0 border-r-0 ${
                                        index % 2 === 0 ? 'hover:bg-purple hover:text-white' : 'hover:bg-red hover:text-white'
                                    }`
                            }>
                                <input type="checkbox"
                                    id={
                                        item.id
                                    }
                                    name={
                                        item.name
                                    }
                                    value={
                                        item.name
                                    }
                                    checked={
                                        checkedId ?. includes(item.name) ? true : false
                                    }
                                    onChange={
                                        (e) => handleCheckBox(e.target.name)
                                    }/>
                                <label> {
                                    item.name
                                } </label>
                            </div>
                        ))
                    } </div>
                )
            } </div>
        </div>
    )
}

export default ContactUsDropDown
