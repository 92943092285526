import { Restro1, Restro2, Restro3 } from "../assets";

export const RestroPhoto = [
    {
        image: Restro1,
        title: "Restro1"
    },
    {
        image: Restro2,
        title: "Restro2"
    },
    {
        image: Restro3,
        title: "Restro3"
    },
]
export const RestroComponenet = [
    {
      title: "Billing",
      info: [
        {
          title: "",
          data: [
            "IRD certified billing engine. Can print estimates and Tax invoice in your design. Table wise billing, table merging, spliting the bill, orderwise billing.",
          ],
        },
      ],
    },
    {
      title: "Ordering",
      info: [
        {
          title: "",
          data: [
            "Manage orders , KOT/BOT , print orders, transfer table or room, tracking orders",
          ],
        },
      ],
    },
    {
      title: "Stocks",
      info: [
        {
          title: "",
          data: [
            "Auto Stock report according to Purchase and Billing(sales). stock adjustment available.",
          ],
        },
      ],
    },
    {
      title: "Purchase",
      info: [
        {
          title: "",
          data: [
            "Purchase module to keep records of purchase items and raw materials.",
          ],
        },
      ],
    },
    {
      title: "Reports",
      info: [
        {
          title: "",
          data: [
            "Varoius reports including daily sales, item wise sales, group wise sales, stock report , Vat Ledger and numerous reports for your business analysis.",
          ],
        },
      ],
    },
  ];