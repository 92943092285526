import { himal_blood_bank, tu_lions_blood_bank } from "../assets";

export const bloodBanSoftwareCustomer = [
    {
        image: himal_blood_bank,
        name: "Himal Blood Transfusion Center"
    },
    {
        image: tu_lions_blood_bank,
        name: "TU Lions Blood Transfusion & Research Center (#TULBTRC)"
    },
]