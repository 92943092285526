import React from "react";

const FeaturesElement = ({ title }) => {
  return (
    <div className="w-max">
      <p className="break-normal text-center bg-backgroundColor rounded-full px-[15px] py-[8px]">
        {title}
      </p>
    </div>
  );
};

export default FeaturesElement;
