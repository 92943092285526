import React, { useEffect, useState } from "react";
import {
  MetaDecorator,
  PageTopComponent,
  Table,
} from "../components";
import { applicationManagementSystem} from "../assets";
import { dashboardData } from "../utils/dashboardData";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import {
  softwareComponents,
  systemRequirements,
} from "../utils/applicationManagementData";

const ApplicationManagementSystem = ({homeInfoData}) => {
  const dispatch = useDispatch();

  // Setting the nav state
  useEffect(() => {
    dispatch(changeNavState("products"));
  }, []);

  const [imageToDisplay, setImageToDisplay] = useState("");

  return (
    <div>
     <MetaDecorator      
        title="IT Consultation"
        titleA="IT Consultation"
        keywords="IT Consultation"
        description="IT Consultation"  
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
      
      <PageTopComponent
        image={applicationManagementSystem}
        title="IT Consultation"
        subtitle="Onviro Tech's IT consultation services provide strategic insights"
        description="Onviro Tech's IT consultation services provide strategic insights, helping clients nevigate the evolving tech landscape, make informed decisions, and optimize their digital initiatives for business success."
      />

      <div className="w-full py-[70px] px-[20px] lg:px-40 flex flex-col">

        {/*...Software Components...*/}
        <div>
          <p className="text-red font-bold text-3xl pt-8">
          Comprehensive Web Development Solutions
          </p>

          <div className="w-full flex gap-[50px] justify-between flex-wrap mt-[40px] ">
            <div className="w-full flex flex-col gap-[10px]">
              <div>
                <p className="w-[70px] h-[70px] rounded-full flex justify-center items-center border border-red text-red font-bold text-xl">
                  {1}
                </p>
              </div>

              <Table data={softwareComponents} />
            </div>
          </div>
        </div>

        {/*...System Requirements....*/}
        <div>
          <p className="text-red font-bold text-3xl pt-8">
            System Requirements
          </p>

          <div className="w-full flex gap-[50px] justify-between flex-wrap mt-[40px] ">
            <div className="w-full flex flex-col gap-[10px]">
              <div>
                <p className="w-[70px] h-[70px] rounded-full flex justify-center items-center border border-red text-red font-bold text-xl">
                  {2}
                </p>
              </div>

              <Table data={systemRequirements} />
            </div>
          </div>
        </div>

        {/*...Dashboard...*/}
        <div className="flex flex-col items-center gap-[30px] mt-[50px]">
          <p className="text-center font-bold text-xl">Dashboard</p>
          <div className="w-full flex justify-center items-center gap-[50px] flex-wrap">
            {dashboardData?.length > 0 &&
              dashboardData.map((item, index) => (
                <div key={index} className="flex flex-col lg:w-[40%] my-8">
                  <img
                    src={item.image}
                    onClick={() => setImageToDisplay(item.image)}
                    className="object-contain cursor-pointer"
                    alt=""
                  />
                  <p className="text-red font-bold text-lg pt-5">
                    {item.title}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationManagementSystem;
