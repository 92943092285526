import React, {useEffect, useState} from "react";
import {Button, MetaDecorator, PageTopComponent} from "../components";
import {Link} from "react-router-dom";
import axiosBaseURL from "../baseUrl";
import {useDispatch} from "react-redux";
import {changeNavState} from "../reduxStore/navSlice";
import {com, cpanel, resellerHosting} from "../assets";
import {MdEmail, MdLocalPhone} from "react-icons/md";

const SpecialWebHosting = ({homeInfoData}) => {
    const dispatch = useDispatch();
    // Setting the nav state
    useEffect(() => {
        dispatch(changeNavState("WebHosting"));
    }, []);

    const [webPageData, setWebPageData] = useState([])

    useEffect(() => {
        axiosBaseURL.get(`api/service/hosting/details`).then((res) => {
            setWebPageData(res.data);
        }).catch((err) => console.log(err));
    }, []);
    const webData = webPageData ?. Hosting_Package;

    return (
        <div>
            <MetaDecorator title="Domain Hosting with Onviro Tech, Domain Hosting in Nepal, Domain Hosting in Nepal, Nepal's Domain Hosting, Apps and Website Develoment company"
                titleA={
                    webPageData ?. meta_title ? webPageData ?. meta_title : ""
                }
                keywords={
                    webPageData ?. meta_keywords ? webPageData ?. meta_keywords : ""
                }
                description={
                    webPageData ?. meta_description ? webPageData ?. meta_description : ""
                }
                ogtitle={
                    homeInfoData ?. og_title ? homeInfoData ?. og_title : ""
                }
                ogdescription={
                    homeInfoData ?. og_descritpion ? homeInfoData ?. og_descritpion : ""
                }
                imageUrl={
                    homeInfoData ?. og_image ? homeInfoData ?. og_image : ""
                }/>

            <PageTopComponent image={
                    webPageData ?. image
                }
                title={
                    webPageData ?. title
                }
                subtitle={
                    webPageData ?. summary
                }
                description={
                    webPageData ?. description
                }/>

            <div className="py-[51px] px-[20px] lg:px-40">
                {/* <p className="text-red text-center lg:text-start font-bold text-3xl">
          Key Information on web hosting service
        </p>
        <div className="flex justify-center items-center flex-wrap gap-[30px] mt-[50px]">
            {bloodBankFeaturesData?.length > 0 &&
              bloodBankFeaturesData.map((item, index) => (
                <FeaturesElement key={index} title={item?.name} />
              ))}
          </div> */}
                {/* <div className="w-full py-8 px-[0px] lg:px-[25%] mt-8">
            {
              webData?.map((item,index)=>(
                <div className="w-full flex flex-col items-center rounded-[5px] bg-[#371E2B] py-8" key={index}>
                <h2 className="text-white font-bold text-[32px] pt-8">{item?.title}</h2>
                <h2 className="text-white font-semibold text-[25px] py-8">Rs. {item?.price}</h2>
                <Link to={"/contact-us"}>
                <button className="rounded-[5px] bg-white px-5 py-2 text-white hover:opacity-90 text-left mb-8">
                 <p className="w-[91px] border-b-2 border-red text-red text-sm font-semibold">Order Now</p>
                </button>
                </Link>
                 </div>
              ))
            }
       
          </div> */}
                {/* learn more Button */}
                {/* <div className="flex justify-center py-8">
             <div className="w-max hidden lg:block">
              <Link to={""}>
                <Button btnName="Learn More about these" />
              </Link>
              </div>
            </div> */}
                <div className="w-full px-[0px] lg:px-[15%]">
                    <div className="w-full flex flex-col items-center rounded-[5px] bg-[#371E2B] p-[6%]">
                        <h2 className="text-white font-bold text-[32px]">Domain Hosting</h2>
                        <h2 className="text-white font-semibold text-[25px] pb-5">(NRs.3500/Year for 10GB Space)
                        </h2>
                        <div className="w-full flex flex-col lg:flex-row items-center lg:items-start justify-evenly lg:gap-[21px] py-5">
                            <ul className="list-disc">
                                <li className="text-white">10 GB NVMe SSD</li>
                                <li className="text-white">Unmetered Bandwidth</li>
                                <li className="text-white">Unlimited Email Accounts</li>
                                <li className="text-white">Unlimited Free SSL (https)</li>
                                <li className="text-white">1-Click WordPress Installer</li>
                                <li className="text-white">Daily Automatic Backups</li>
                            </ul>
                            <ul className="list-disc">
                                <li className="text-white">Unmetered DDoS Protection</li>
                                <li className="text-white">Live Malware Protection</li>
                                <li className="text-white">LiteSpeed Web Server</li>
                                <li className="text-white">Node.js, Python, Ruby, Git</li>
                                <li className="text-white">Softaculous</li>
                                <li className="text-white">Instant Setup & Activation</li>
                            </ul>
                        </div>
                        <div className="w-full flex flex-col 2xl:flex-row items-center 2xl:items-start justify-evenly lg:gap-[21px] pt-8">
                            <Link to={
                                    `mailto:${'mbroka@onvirotech.com'}`
                                }
                                target="_blank"
                                className="flex text-white font-medium whitespace-nowrap"><MdEmail className="text-[21px] mr-2"/>mbroka@onvirotech.com</Link>
                            <Link to={
                                    `tel:${'977-9851033907'}`
                                }
                                target="_blank"
                                className="flex text-white font-medium whitespace-nowrap"><MdLocalPhone className="text-[21px] mr-2"/>+977-9851033907</Link>
                            <Link to={
                                    `tel:${'977-1-4950179'}`
                                }
                                target="_blank"
                                className="flex text-white font-medium whitespace-nowrap"><MdLocalPhone className="text-[21px] mr-2"/>+977-1-4950179</Link>
                        </div>
                        {/* <Link to={"/contactus"}>
                <button className="rounded-[5px] bg-white px-5 py-2 text-white hover:opacity-90 text-left">
                 <p className="w-[91px] border-b-2 border-red text-red text-sm font-semibold">Order Now</p>
                </button>
                </Link> */} </div>
                    <div className="flex justify-center py-8">
                        <div className="w-max hidden lg:block">
                            <Link to={"/contactus"}>
                                <Button btnName="Order Now"/>
                            </Link>
                        </div>
                    </div>

                    <div className="flex gap-[21px] justify-evenly flex-wrap">
                        <div className="flex flex-col justify-center items-center">
                            <img src={resellerHosting}
                                alt="resellerHosting"
                                className="w-[151px] h-[151px] object object-contain"/>
                                {/* <h2 className="font-semibold text-dark text-[18px]">Resellers Hosting</h2> */}
                        </div>
                        <div className="flex flex-col justify-center items-center">
                            <img src={com}
                                alt="com"
                                className="w-[151px] h-[151px] object object-contain"/>
                                {/* <h2 className="font-semibold text-dark text-[18px]">Domain Registration</h2> */}
                        </div>
                        <div className="flex flex-col justify-center items-center">
                            <img src={cpanel}
                                alt="cpanel"
                                className="w-[151px] h-[151px] object object-contain"/>
                                {/* <h2 className="font-semibold text-dark text-[18px]">Shared Web Hosting</h2> */}
                        </div>
                    </div>
                    <h2 className="font-normal text-dark text-[17px] text-center italic 2xl:whitespace-nowrap"><span className="text-[21px] font-bold text-[#7b0005] not-italic">Deal with us for  -</span> Domain Registration || Web Hosting || Website Development || Apps Development || Logo Design</h2>
                </div>
            </div>
        </div>
    );
};

export default SpecialWebHosting;
