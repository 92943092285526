import React from "react";
import "../styles/style.css";
import { Link } from "react-router-dom";

const Button = ({ btnName, type, link }) => {
  return (
    <div
      style={{ background: "#7B0005" }}
      className="rounded-[5px] px-6 py-2 text-white hover:opacity-90 text-left gap-2"
    >
      {link ? (
        <Link to={link}>
          <button
            type={type}
            className="w-max pr-[30px] border-b-2 border-white text-white text-start"
          >
            {btnName}
          </button>
        </Link>
      ) : (
        <button
          type={type}
          className="w-max pr-[30px] border-b-2 border-white text-white text-start"
        >
          {btnName}
        </button>
      )}
    </div>
  );
};

export default Button;
