import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const metaDecorator = require("../metaDecorator.json");

const MetaDecorator = ({ ogdescription, ogtitle, titleA, title, description, imageUrl, keywords }) => (
  <Helmet>
    <title>{title}</title>   
    <meta name="title" content={titleA} />
    <meta name="keywords" content={keywords} />   
    <meta name="description" content={description} />
    <meta name="site_name" property="og:site_name" content="Onviro Tech Pvt.Ltd"/> 
    <meta name="site" property="og:site" content={metaDecorator.hostname}/>
    <meta name="title" property="og:title" content={ogtitle} />
    <meta name="description" property="og:description" content={ogdescription} />
    <meta name="image" property="og:image" content={imageUrl} />
    <meta name="url" property="og:url" content={metaDecorator.hostname + window.location.pathname + window.location.search} />
    <meta name="type" property="og:type" content="article"/>
    <meta name="author" content="Onviro Tech Pvt.Ltd"></meta>
    {/* <meta name="twitter:image:alt" content={imageUrl} />
    <meta name="twitter:site" content={metaDecorator.twitterUsername} />
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={metaDecorator.hostname} />
    <meta property="twitter:title" content={titleA} />
    <meta property="twitter:description" content={ogdescription} />
    <meta property="twitter:image" content={imageUrl} /> */}
  </Helmet>
);

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  ogdescription: PropTypes.string.isRequired,
  ogtitle: PropTypes.string.isRequired,
  titleA: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
};

export default MetaDecorator;
