import React, { useEffect, useState } from "react";
import { Logo } from "../assets";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import "../styles/style.css";
import { Button } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDropdown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { changeNavState } from "../reduxStore/navSlice";

const Navbar = ({navData}) => {
  const [activeNavItemIndex, setActiveNavItemIndex] = useState();

  const navItems = [
    {
      menuTitle: "Home",
      link: "/",
      slug: "home",
    },
    {
      menuTitle: "About Us",
      link: "/aboutus",
      slug: "about-us",
    },
    {
      menuTitle: "Services",
      link: "/services",
      slug: "services",
    },

    {
      menuTitle: "Projects",
      link: "/projects",
      slug: "projects",
    },
    {
      menuTitle: "Products",
      link: "",
      slug: "products",
      subCatagories: [
        {
          menuTitle: "J-Jewellery Software",
          link: "/services/Jewellery",
          slug: "",
        },
        {
          menuTitle: "e-Tender Application",
          link: "/services/e-Tender-Application",
          slug: "",
        },
        {
          menuTitle: "Blood Bank Software",
          link: "/services/blood-bank-software",
          slug: "",
        },
        {
          menuTitle: "Restrobyte",
          link: "/services/restrobyte",
          slug: "",
        },
        {
          menuTitle: "Mobile Application Development",
          link: "/services/mobile-applications",
          slug: "",
        },
      ],
    },
    {
      menuTitle: "Web Hosting",
      link: "/services/web-hosting",
      slug: "WebHosting",
    },
  ];

  const navLink = [
    {
      menuTitle: "Home",
      link: "/",
      slug: "home",
    },
    {
      menuTitle: "About Us",
      link: "/aboutus",
      slug: "about-us",
    },
    {
      menuTitle: "Services",
      link: "/services",
      slug: "services",
    },

    {
      menuTitle: "Projects",
      link: "/projects",
      slug: "projects",
    },
    {
      menuTitle: "Products",
      link: "/products",
      slug: "products",
      subCatagories: [
        {
          menuTitle: "J-Jewellery Software",
          link: "/services/Jewellery",
          slug: "",
        },
        {
          menuTitle: "e-Tender Application",
          link: "/services/e-Tender-Application",
          slug: "",
        },
        {
          menuTitle: "Blood Bank Software",
          link: "/services/blood-bank-software",
          slug: "",
        },
        {
          menuTitle: "Restrobyte",
          link: "/services/restrobyte",
          slug: "",
        },
        {
          menuTitle: "Mobile Application Development",
          link: "/services/mobile-applications",
          slug: "",
        },
      ],
    },
    {
      menuTitle: "Web Hosting",
      link: "/services/web-hosting",
      slug: "WebHosting",
    },
  ];
  const dispatch = useDispatch();

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [dropdownId, setDropdownId] = useState();

  // Get Active nav State
  const activeNav = useSelector((state) => state.navState.activeNav);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <>
      {" "}
      {/*....Nav Section...*/}
      <nav
        className="w-full"
        style={{
          zIndex: 99,
          position: "sticky",
          top: 0,
          left: 0,
        }}
      >
        <div className="w-full bg-white relative shadow-md">
          {/*....Logo Section...*/}
          <div className="w-full flex justify-between items-center px-[20px] lg:px-[100px] py-1">
            <Link to="/">
              <img
                src={navData?.site_logo}
                className="py-[5px] lg:w-fit object-contain"
                alt="logo"
              style={{
                width:"195px",
                height:"61px",
              }}
              />
            </Link>

            <div
              onMouseLeave={() => setActiveNavItemIndex(null)}
              className="hidden w-full font-roboto lg:flex justify-center items-center gap-[30px] py-[10px] navList"
            >
              {navLink.map((item, index) => (
                <Link  to={item.link} key={index} className="font-inter font-semibold">
                  {item.subCatagories?.length > 0 ? (
                    <div
                      key={index}
                      onMouseEnter={() => setActiveNavItemIndex(index)}
                      className={`${
                        activeNav === item?.slug && "text-red"
                      } hover:text-red relative flex flex-col items-center`}
                    >
                      {item?.menuTitle}
                      {activeNavItemIndex === index &&
                        item.subCatagories?.length > 0 && (
                          <div
                            onMouseLeave={() => setActiveNavItemIndex(null)}
                            className="w-max absolute top-[30px] left-[50%] transform -translate-x-[50%] bg-white flex flex-col text-dark text-start font-[16px] z-30 rounded-b-[6px] shadow-lg"
                          >
                            {item.subCatagories.map((item, index) => (
                              <Link
                                key={index}
                                to={item.link}
                                onClick={() => dispatch(changeNavState(""))}
                                className={`${
                                  index % 2 === 0
                                    ? "hover:bg-red hover:text-white"
                                    : "hover:bg-purple hover:text-white"
                                } py-[15px] px-[10px]`}
                              >
                                {item.menuTitle}
                              </Link>
                            ))}
                          </div>
                        )}
                    </div>
                  ) : (
                    <Link
                      onMouseEnter={() => setActiveNavItemIndex(index)}
                      to={item?.link}
                      className={`${
                        activeNav === item?.slug && "text-red"
                      } hover:text-red relative flex flex-col items-center`}
                    >
                      {item?.menuTitle}
                    </Link>
                  )}
                </Link>
              ))}
            </div>

            {/* Mobile menu */}
            {showMobileMenu && (
              <div
                style={{
                  zIndex: 99,
                  animation: "sidebarAnimate linear 0.2s",
                }}
                className="w-[80%] h-[100%] lg:hidden fixed top-[0px] right-0 flex flex-col gap-[30px] overflow-y-scroll scrollbar-hide bg-white transition ease-out delay-1000"
              >
                {/*....Cross...*/}
                <div
                  onClick={() => setShowMobileMenu(false)}
                  className="bg-red cursor-pointer absolute top-[20px] right-[20px] w-[30px] h-[30px] rounded-full shadow-lg flex justify-center items-center"
                >
                  <RxCross2 className="text-xl text-white" />
                </div>

                {/*...Nav Item..*/}
                <div className="w-full flex flex-col items-start px-2 pt-2 pb-3 mt-[70px]">
                  {navItems.map((item, index) => (
                    <div key={index}>
                      {item.subCatagories?.length > 0 ? (
                        <div
                          onClick={() => {
                            setIsDropdownActive((prev) => !prev);
                            setDropdownId(index);
                          }}
                          className="flex items-center justify-between gap-[10px] px-3 py-2 rounded-md text-base font-medium hover:text-gray-300"
                        >
                          <div>
                            <p>{item.menuTitle}</p>
                          </div>
                          <div>
                            <IoIosArrowDropdown />
                          </div>
                        </div>
                      ) : (
                        <Link
                          to={item.link}
                          key={index}
                          onClick={() => setShowMobileMenu(false)}
                          className="block px-3 py-2 rounded-md text-base font-medium hover:text-gray-300"
                        >
                          {item.menuTitle}{" "}
                        </Link>
                      )}
                      {isDropdownActive &&
                        dropdownId === index &&
                        item.subCatagories?.map((item, index) => (
                          <Link
                            to={item.link}
                            key={index}
                            onClick={() => setShowMobileMenu(false)}
                            className="block px-3 py-2 pl-[20px] rounded-md text-slate-500 font-medium hover:text-gray-300"
                          >
                            {item.menuTitle}{" "}
                          </Link>
                        ))}{" "}
                    </div>
                  ))}{" "}
                </div>
              </div>
            )}
            {/* Contact Us Button */}
            <div className="w-max hidden lg:block">
              <Link to={"/contactus"}>
                <Button btnName="Contact Us" />
              </Link>
            </div>

            {/* Responsive Button */}
            <button
              onClick={toggleMobileMenu}
              className="lg:hidden hover:text-gray-300 focus:outline-none"
            >
              <FaBars className="h-6 w-6" />
            </button>
          </div>
        </div>
      </nav>
      {/*...Overlay..*/}
      {showMobileMenu && (
        <div className="lg:hidden bg-black/25 z-10 fixed w-full h-screen"></div>
      )}{" "}
    </>
  );
};

export default Navbar;