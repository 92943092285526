import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Button, MetaDecorator } from "../components";
import { Link, useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import axiosBaseURL from "../baseUrl";

const SpecificJobPage = ({homeInfoData}) => {
  let { slug } = useParams();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [resume, setResume] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [githubLink, setGithubLink] = useState("");

  const [loading, setLoading] = useState(false);
  const [careerDetails, setCareerDetails] = useState();

  // Fetch Career list
  const fetchCareerDetails = async () => {
    try {
      setLoading(true);
      await axiosBaseURL.get(`api/career/${slug}`).then((res) => {
        if (res?.data) {
          setLoading(false);
          setCareerDetails(res.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCareerDetails();
  }, [slug]);

  // Handle file change
  function handleFileChange(event) {
    const file = event.target.files[0];
    setResume(file);
  }
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      fullName,
      email,
      resume,
      expectedSalary,
      githubLink,
    };

    try {

      await axiosBaseURL.post("api/career/store", formData).then((res) => {
        console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
            <MetaDecorator      
        title="Jobs"
        titleA="Jobs"
        keywords="Jobs"
        description="Jobs"
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
             
      {/*..View All Jobs..*/}
      <Link
        to={"/career"}
        className="flex items-center text-dark px-[20px] lg:px-globalPadding mt-[60px]"
      >
        <MdKeyboardArrowLeft className="text-xl" />
        <p>View all jobs</p>
      </Link>

      <p className="text-red text-2xl font-bold text-center py-[30px]">
        Current Openings
      </p>

      {/*...Job Post Info..*/}
      <div className="w-full bg-backgroundColor px-[20px] lg:px-globalPadding py-[20px] ">
        {/*...Job title...*/}
        <p className="text-red font-bold">Backend Developer (2)</p>
        {/*...Info...*/}
        <div></div>
      </div>

      {/*....Objectives and apply...*/}
      <div className="w-full flex flex-col lg:flex-row items-center lg:items-start gap-[60px] px-[20px] lg:px-40 py-[40px] ">
        {/*...Objectives...*/}
        <div className="w-full lg:w-[50%] flex flex-col gap-[20px] ">
          <p className="text-red font-bold">Key Objectives</p>
          <div
            className="w-full flex flex-col gap-[20px]"
            dangerouslySetInnerHTML={{
              __html: careerDetails?.description
                ? careerDetails.description
                : "",
            }}
          ></div>

          <div
            className="w-full flex flex-col gap-[20px]"
            dangerouslySetInnerHTML={{
              __html: careerDetails?.specification
                ? careerDetails.specification
                : "",
            }}
          ></div>
        </div>
        {/*...Application form...*/}
        <div className="w-full lg:w-[50%] ">
          <p className="text-red font-bold">Apply for this position</p>

          <form
            onSubmit={handleSubmit}
            className="w-full mt-[30px] flex flex-col gap-[20px]"
          >
            {/*...Name...*/}
            <label className="w-full flex flex-col items-start gap-[5px]">
              <p className="text-dark">
                Full Name<span className="text-red">*</span>
              </p>
              <input
                type="text"
                placeholder="Enter Your Name"
                onChange={(e) => setFullName(e.target.value)}
                className="w-full bg-backgroundColor p-[9px] rounded-[6px]"
              />
            </label>
            {/*....Email....*/}
            <label className="w-full flex flex-col items-start gap-[5px]">
              <p className="text-dark">
                Email<span className="text-red">*</span>
              </p>
              <input
                type="email"
                placeholder="Enter your email address"
                onChange={(e) => setEmail(e.target.value)}
                className="w-full bg-backgroundColor p-[9px] rounded-[6px]"
              />
            </label>
            {/*....Resume....*/}
            <label className="w-full flex flex-col items-start gap-[5px]">
              <p className="text-dark">
                Resume<span className="text-red">*</span>
              </p>
              <label
                htmlFor="upload-resume"
                className="w-max bg-backgroundColor p-[9px] rounded-[6px] text-slate-400 underline underline-offset-8 cursor-pointer"
              >
                {resume ? (
                  <div className="flex items-start gap-[5px] w-max border rounded-xl p-[10px] ">
                    <p>{resume.name}</p>
                    <RxCross2 onClick={() => setResume("")} className="" />
                  </div>
                ) : (
                  <p>Attach Resume</p>
                )}
              </label>
              <input
                id="upload-resume"
                type="file"
                placeholder="Attach Resume"
                onChange={handleFileChange}
                className="custom-file-input"
              />
            </label>
            {/*...Expected Salary...*/}
            <label className="w-full flex flex-col items-start gap-[5px]">
              <p className="text-dark">Expected Salary</p>
              <input
                type="text"
                placeholder="Enter your expected salary range"
                onChange={(e) => setExpectedSalary(e.target.value)}
                className="w-full bg-backgroundColor p-[9px] rounded-[6px]"
              />
            </label>
            {/*....Github Link....*/}
            <label className="w-full flex flex-col items-start gap-[5px]">
              <p className="text-dark">
                GitHub Link<span className="text-red">*</span>
              </p>
              <input
                type="text"
                placeholder="Paste the link here"
                onChange={(e) => setGithubLink(e.target.value)}
                className="w-full bg-backgroundColor p-[9px] rounded-[6px]"
              />
            </label>

            <div className="w-max mt-[10px]">
              <Button type="submit" btnName="Submit Application" />
            </div>
          </form>
        </div>
      </div>

      {/*...Didn't find Preferred position...*/}
      <div className="flex flex-col items-center gap-[20px] my-[50px] ">
        <p className="text-red text-lg">Didn’t find your preferred position?</p>
        <div className="w-max">
          <Button btnName="Drop your CV" link="/drop-cv" />
        </div>
      </div>
    </div>
  );
};

export default SpecificJobPage;