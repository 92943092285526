export const softwareComponents = [
  {
    title: "Custom Web Design",
    info: [
      {
        title: "",
        data: [
          "We provide custom web design services that reflect your brand identity and align with your business goals. This involves creating a unique layout, selecting a suitable color palette, and using appropriate typography and graphics.",
        ],
      },
    ],
  },
  {
    title: "Responsive Design",
    info: [
      {
        title: "",
        data: [
          "We ensure that your website is responsive and mobile-friendly, meaning it adjusts its layout to fit different screen sizes and resolutions",
        ],
      },
    ],
  },
  {
    title: "SEO Optimization",
    info: [
      {
        title: "",
        data: [
          "We provide SEO optimization services to improve your website's visibility in search engine results pages (SERPs). This includes keyword research, on-page optimization, and off-page optimization.",
        ],
      },
    ],
  },
  {
    title: "Content Management System (CMS)",
    info: [
      {
        title: "",
        data: [
          "We develop a CMS using Laravel that allows you to manage your website's content without requiring technical skills. This includes creating new pages, uploading images and videos, and managing user accounts.",
        ],
      },
    ],
  },
  {
    title: "E-commerce Integration",
    info: [
      {
        title: "",
        data: [
          "The IT company can integrate e-commerce functionality into your website using Laravel and React, allowing you to sell products or services online. This includes creating a shopping cart, checkout process, and payment gateway integration.",
        ],
      },
    ],
  },
  {
    title: "API Integration",
    info: [
      {
        title: "",
        data: [
          "The IT company can integrate third-party APIs into your website using React, allowing you to access data and functionality from external sources. This includes social media APIs, payment gateway APIs, and more.          ",
        ],
      },
    ],
  },
  {
    title: "Testing and Debugging",
    info: [
      {
        title: "",
        data: [
          "The IT company can provide testing and debugging services to ensure your website works correctly and is free of errors. This includes automated and manual testing, bug fixing, and quality assurance.",
        ],
      },
    ],
  },
];

export const technology = [
  {
    title: "Technologies Used",
    info: [
      {
        title: "",
        data: ["Figma", "React.js", "Laravel Framework of PHP"],
      },
    ],
  },
];

export const systemRequirements = [
  {
    title: "Server",
    info: [
      {
        title: "Online",
        data: [
          "1GB storage",
          "2GB RAM",
          "The more bandwidth, the better in experience",
        ],
      },
    ],
  },
];
