import React, { useEffect, useState } from "react";
import { Button, CareerPosition, MetaDecorator, PageTopComponent } from "../components";
import { joinTeam } from "../assets";
import axiosBaseURL from "../baseUrl";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";

const Career = ({homeInfoData}) => {
  const dispatch = useDispatch();

  // Setting the nav state
  useEffect(() => {
    dispatch(changeNavState(""));
  }, []);

  const [loading, setLoading] = useState(false);
  const [careerList, setCareerList] = useState([]);

  // Fetch Career list
  const fetchCareerList = async () => {
    try {
      setLoading(true);
      await axiosBaseURL.get(`api/career/list`).then((res) => {
        if (res?.data?.data?.length > 0) {
          setLoading(false);
          setCareerList(res.data.data);
          return;
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCareerList();
  }, []);

  return (
    <div>
           <MetaDecorator      
        title="Career"
        titleA="Career"
        keywords="Career"
        description="Career"  
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
      
      {/*...Top Section...*/}
      <PageTopComponent
        title="Join The Team"
        subtitle="Unleash Your Potential: Join Our Dynamic IT Team"
        description="Are you ready to take your career to the next level? Join our dynamic IT team and unleash your potential in a collaborative and innovative environment. We are passionate about leveraging technology to drive impactful solutions for our clients. As part of our team, you'll have the opportunity to work on exciting projects, expand your skills, and contribute to cutting-edge advancements. Join us and be part of a team that values creativity, teamwork, and professional growth. Take the leap and join our team today!"
        image={joinTeam}
        btnName={"About Us"}
        btnLink='/AboutUsPage'
      />

      {/*...Lower section...*/}
      <div className="w-full flex flex-col items-center gap-[20px] px-[20px] lg:px-40 py-[80px]">
        <p className="text-center text-dark">About Us / Careers</p>
        <p className="text-red text-2xl font-bold text-center">
          Current Openings
        </p>

        {/*...Job Openings Positions...*/}
        <div className="w-full lg:w-[700px] flex flex-col gap-[20px] ">
          {careerList?.length > 0 &&
            careerList?.map((item, index) => (
              <CareerPosition
                key={index}
                jobTitle={item?.post}
                vacancyNumber={item?.opening_number}
                slug={item?.slug}
              />
            ))}
        </div>

        {/*...Didn't find Preferred position...*/}
        <div className="flex flex-col items-center gap-[20px] mt-[50px] ">
          <p className="text-red text-lg">
            Didn’t find your preferred position?
          </p>
          <div className="w-max">
            <Button btnName="Drop your CV" link="/drop-cv" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;