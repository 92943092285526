import React, { useEffect, useState } from "react";
import {
  MetaDecorator,
  PageTopComponent,
  Table,
} from "../components";
import { Restro} from "../assets";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import {
  softwareComponents,
  systemRequirements,
} from "../utils/applicationManagementData";
import { RestroComponenet, RestroPhoto } from "../utils/restroData";

const Restrobyte = ({homeInfoData}) => {
  const dispatch = useDispatch();

  // Setting the nav state
  useEffect(() => {
    dispatch(changeNavState("products"));
  }, []);

  const [imageToDisplay, setImageToDisplay] = useState("");

  return (
    <div>
     <MetaDecorator      
        title="Restrobyte"
        titleA="Restrobyte"
        keywords="Restrobyte"
        description="Restrobyte"  
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
      
      <PageTopComponent
        image={Restro}
        title="Restrobyte"
        subtitle="Restaurant management and Billing system"
        description="We are glad to inform you that RestroByte is now accessible to assist your Restaurant and Hotel management. RestroByte is easy to use and can be implemented imidiately. It is very useful for ordering, billing and managing stocks.        "
      />

      <div className="w-full py-[70px] px-[20px] lg:px-40 flex flex-col">

        {/*...Software Components...*/}
        <div>
          <p className="text-red font-bold text-3xl pt-8 capitalize">
          FEATURES OF RESTROBYTES
          </p>

          <div className="w-full flex gap-[50px] justify-between flex-wrap mt-[40px] ">
            <div className="w-full flex flex-col gap-[10px]">

              <Table data={RestroComponenet} />
            </div>
          </div>
        </div>

        {/*...Dashboard...*/}
        <div className="flex flex-col items-center gap-[30px] mt-[50px]">
          <p className="text-center font-bold text-xl">Dashboard</p>
          <div className="w-full flex justify-center items-center gap-[50px] flex-wrap">
            {RestroPhoto?.length > 0 &&
              RestroPhoto.map((item, index) => (
                <div key={index} className="flex flex-col lg:w-[40%] my-8">
                  <img
                    src={item.image}
                    onClick={() => setImageToDisplay(item.image)}
                    className="object-contain cursor-pointer"
                    alt=""
                  />
                  <p className="text-red font-bold text-lg pt-5">
                    {item.title}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Restrobyte;
