import React, { useEffect } from "react";
import { MetaDecorator, PageTopComponent, SingleFaq } from "../components";
import { onvirogroup } from "../assets";
import { serviceList } from "../utils/serviceList";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";

const OurServices = ({homeInfoData}) => {
  const dispatch = useDispatch();

  // Setting the nav state
  useEffect(() => {
    dispatch(changeNavState("services"));
  }, []);

  return (
    <div>
                <MetaDecorator      
        title="Our Services"
        titleA="Our Services"
        keywords="Our Services"
        description="Our Services"
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
      
      <PageTopComponent
        title="Services we provide"
        subtitle="With over 1000 clients satisfaction, we provide one of the best service."
        description="As a tech company, we offer a comprehensive suite of services designed to leverage the power of technology and drive digital innovation. Our expertise spans across software development, mobile app development, web design and development, cloud computing solutions, data analytics, artificial intelligence, and machine learning. We work closely with our clients to understand their unique business requirements and deliver customized solutions that streamline processes, enhance productivity, and enable seamless digital experiences. With a strong focus on cutting-edge technologies and industry best practices, we help businesses stay at the forefront of digital transformation, driving growth and success in today's technology-driven world."
        image={onvirogroup}
      />

      {/*...Our Services section...*/}
      {serviceList?.length > 0 && (
        <div className="w-full mt-[50px] mb-[50px] lg:mb-[100px] px-4 lg:px-40">
          <p className="text-center text-red font-bold text-2xl pt-8">
            We can help you with
          </p>

          <div className="w-full bg-backgroundColor mt-[30px]">
            {serviceList.map((item, index) => (
              <SingleFaq
                key={index}
                title={item.title}
                slug={item.slug}
                hoverColor={
                  index % 2 === 0
                    ? "hover:bg-red hover:text-white"
                    : "hover:bg-purple hover:text-white"
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OurServices;
