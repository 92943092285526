import React, { useEffect } from 'react'
import {ContactImage} from '../assets'
import {MetaDecorator, PageTopComponent, ProductsCard} from '../components';
import { useDispatch } from 'react-redux';
import { changeNavState } from '../reduxStore/navSlice';
import { productsListData } from '../utils/productsListData';


const ProductsPage = ({homeInfoData}) => {
    const dispatch = useDispatch();

    // Setting the nav state
    useEffect(() => {
      dispatch(changeNavState("products"));
    }, []);

    return (
        <div> 
                    <MetaDecorator      
        title="Our Flagship Products"
        titleA="Our Flagship Products"
        keywords="Our Flagship Products"
        description="Our Flagship Products"
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
              
            {/*...Title top...*/}
            <div className='w-full'>
                <PageTopComponent title="Our Flagship Products" subtitle="Empowering Digital Transformation Innovations and Solutions" description="Our proudest product is the culmination of meticulous craftsmanship and innovation. From its inception, our team dedicated countless hours to designing, refining, and perfecting every aspect. We took great care in selecting high-quality materials, ensuring durability and superior performance. Through rigorous testing and continuous improvement, we have created a product that exceeds customer expectations. Our proudest product showcases our unwavering commitment to excellence, delivering a seamless user experience and addressing the specific needs of our customers. Its exceptional design, functionality, and attention to detail set it apart from competitors, making it a source of pride for our entire team."
                    image={ContactImage}/>
            </div>

            {/* Product Section */}
            <div className="w-full px-[11px] flex justify-between px-4 lg:px-40 py-5 lg:py-8">
            <div className="w-full flex flex-col lg:flex-row gap-[21px]">
                        {
                            productsListData?.map((item,index)=>(
                           <ProductsCard data={item} key={index}/>
                            ))
                        }
                        </div>  
                    </div>
        </div>
    )
}

export default ProductsPage
