import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./globals/NavBar";
import Footer from "./globals/Footer";
import {
  AboutUsPage,
  ApplicationManagementSystem,
  BloodBankSoftware,
  Career,
  ContactUs,
  ETender,
  EcommerceProjects,
  GenerativeAi,
  Home,
  IndividualServices,
  Jewellery,
  LogoDesign,
  MobileApplication,
  NoDataFound,
  OurServices,
  PreferredJobPosition,
  ProductsPage,
  ProjectsPage,
  SpecialWebHosting,
  SpecificJobPage,
} from "./pages";
import axiosBaseURL from "./baseUrl";
import { MetaDecorator } from "./components";
import Restrobyte from "./pages/Restrobyte";
import { Helmet } from "react-helmet";
// const ogUrl = "https://onviro.onvirotech.com/";

function WindowScrollTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}

function App() {
  {
    /* Contact API */
  }
  const [contactData, setContactData] = useState(null);
  useEffect(() => {
    axiosBaseURL
      .get("api/office/list", {})
      .then((res) => {
        setContactData(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  {
    /* ContactInfo API */
  }
  const [contactInfoData, setContactInfoData] = useState(null);
  useEffect(() => {
    axiosBaseURL
      .get("api/site_info", {})
      .then((res) => {
        setContactInfoData(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  {
    /* Index API */
  }
  const [indexData, setIndexData] = useState(null);
  useEffect(() => {
    axiosBaseURL
      .get("api/home", {})
      .then((res) => {
        setIndexData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  {
    /* About Us API */
  }
  const [aboutData, setAboutData] = useState(null);
  useEffect(() => {
    axiosBaseURL
      .get("api/about", {})
      .then((res) => {
        setAboutData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="App">
      <>
        <MetaDecorator
          title="Onviro Tech Pvt.Ltd"
          titleA={
            contactInfoData?.meta_title ? contactInfoData?.meta_title : ""
          }
          keywords={
            contactInfoData?.meta_keywords ? contactInfoData?.meta_keywords : ""
          }
          description={
            contactInfoData?.meta_description
              ? contactInfoData?.meta_description
              : ""
          }
          ogtitle={contactInfoData?.og_title ? contactInfoData?.og_title : ""}
          ogdescription={
            contactInfoData?.og_description
              ? contactInfoData?.og_description
              : ""
          }
          imageUrl={contactInfoData?.og_image ? contactInfoData?.og_image : ""}
        />
  <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-EBCFFWEY7M"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-EBCFFWEY7M');
          `}
        </script>
      </Helmet>
        <WindowScrollTop />
        <NavBar navData={contactInfoData} />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                homeData={indexData}
                aboutData={aboutData}
                homeInfoData={contactInfoData}
              />
            }
          />
          <Route
            path="/contactus"
            element={
              <ContactUs
                footerInfoData={contactInfoData}
                homeInfoData={contactInfoData}
              />
            }
          />
          <Route
            path="/aboutus"
            element={
              <AboutUsPage
                aboutData={aboutData}
                homeInfoData={contactInfoData}
              />
            }
          />
          <Route
            path="/products"
            element={<ProductsPage homeInfoData={contactInfoData} />}
          />
          <Route
            path="/projects"
            element={<ProjectsPage homeInfoData={contactInfoData} />}
          />
          <Route
            path="/career"
            element={<Career homeInfoData={contactInfoData} />}
          />
          <Route
            path="/career/:slug"
            element={<SpecificJobPage homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services"
            element={<OurServices homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/:slug"
            element={<IndividualServices homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/offshore-team-management"
            element={<BloodBankSoftware homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/e-Tender-Application"
            element={<ETender homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/Jewellery"
            element={<Jewellery homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/blood-bank-software"
            element={<BloodBankSoftware homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/website-development"
            element={<EcommerceProjects homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/software-solutions"
            element={<LogoDesign homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/mobile-applications"
            element={<MobileApplication homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/domain-hosting"
            element={<SpecialWebHosting homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/it-consultation"
            element={
              <ApplicationManagementSystem homeInfoData={contactInfoData} />
            }
          />
          <Route
            path="/services/restrobyte"
            element={<Restrobyte homeInfoData={contactInfoData} />}
          />
          <Route
            path="/services/generative-ai"
            element={<GenerativeAi homeInfoData={contactInfoData} />}
          />
          <Route
            path="/drop-cv"
            element={<PreferredJobPosition homeInfoData={contactInfoData} />}
          />
          <Route path="*" element={<NoDataFound />} />
        </Routes>

        <Footer footerData={contactData} footerInfoData={contactInfoData} />
      </>
    </div>
  );
}

export default App;
