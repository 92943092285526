import React, {useEffect, useState} from "react";
import "../styles/style.css";
import {Link} from "react-router-dom";
import {Logo, Qr, cluch} from "../assets";
import {MdLocationPin, MdLocalPhone, MdEmail} from "react-icons/md";
import {TbWorld} from "react-icons/tb";
import {FaLinkedin, FaFacebook, FaAngleUp} from "react-icons/fa";
import {Button} from "../components";

const Footer = ({footerData, footerInfoData}) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => { // Add event listener to check scroll position on mount
        window.addEventListener('scroll', handleScroll);
        // Clean up the event listener on component unmount
        return() => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => { // Show the button when the user scrolls beyond a certain threshold
        const threshold = 300;
        const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
        setIsVisible(currentScrollPos > threshold);
    };

    const scrollToTop = () => { // Scroll to the top of the page
        window.scrollTo({
            top: 0, behavior: 'smooth' // For smooth scrolling behavior
        });
    }

    return (
        <> {/*....Left fixed social Icons...*/}
            <div className="w-full hidden lg:block">
                <ul className="w-8 py-2 px-2 list-none bg-[#E7DADA] drop-shadow fixed top-1/2 transform -translate-y-1/2 left-0">
                    <li className="py-2">
                        <Link to={
                                footerInfoData ?. linkedin_link
                            }
                            target="_blank">
                            <FaLinkedin className="text-xs lg:text-base text-[#7B0005] hover:text-slate-800"/>
                        </Link>
                    </li>
                    <li className="py-2">
                        <Link to={
                                footerInfoData ?. clutch_link
                            }
                            target="_blank">
                            <img src={cluch}
                                className="w-6 h-auto lg:w-8"
                                alt="logo"/>
                        </Link>
                    </li>
                    <li className="py-2">
                        <Link to={
                                footerInfoData ?. facebook_link
                            }
                            target="_blank">
                            <FaFacebook className="text-xs lg:text-base text-[#7B0005] hover:text-slate-800"/>
                        </Link>
                    </li>
                </ul>
            </div>


            {/*....Back to top Button...*/}

            <button className={
                    `back-to-top-button ${
                        isVisible ? 'visible' : ''
                    }`
                }
                onClick={scrollToTop}>
                <FaAngleUp className="text-[21px]"/>
            </button>


            {/*....Footer Section...*/}
            <div className="w-full bg-[#E7DADA]">
                <div className="w-full items-center px-[20px] lg:px-40 pt-[35px]">
                    <div className="w-full flex justify-between border-b border-b-[#7B0005] py-5">
                        <p className="font-bold text-[#7B0005] text-[16px] lg:text-[27px]">
                            We help you grow
                        </p>

                        {/* Footer Contact Us Button */}
                        <Link to={'/contactus'}>
                            <div className="w-max">
                                <Button btnName="Contact Us"/>
                            </div>
                        </Link>

                    </div>
                    {/* Footer Info */}
                    <div className="w-full flex flex-col lg:flex-row justify-between border-b border-b-[#7B0005] py-5">
                        <div className="lg:w-[45%]">
                            <Link to="/">
                                <img src={
                                        footerInfoData ?. footer_logo
                                    }
                                    className="py-[15px] lg:w-fit object-contain"
                                    alt="logo"
                                    style={
                                        {
                                            width: "201px",
                                            height: "107px"
                                        }
                                    }/>
                            </Link>
                            <p className="font-medium text-[#0F0A1A]">We provide end-to-end technology solutions, including software development, web design, cloud computing, and AI. Our goal is to empower businesses with innovative tech solutions that drive growth, efficiency, and digital transformation.</p>
                        </div>
                        <div className="flex items-center justify-center pt-2 lg:pt-0 lg:justify-end">
                            <img src={Qr}
                                className="w-[180px] h-[180px] lg:w-[224px] lg:h-[224px] p-[5px] lg:w-fit bg-[#fff]"
                                alt="logo"/>

                        </div>

                    </div>
                    {/* Footer pages */}
                    <div className="w-full flex justify-between border-b border-b-[#7B0005] py-4 font-semibold text-[11px] lg:text-[16px]">
                        <Link to="/aboutus" className="hover:text-slate-600">About Us</Link>
                        <Link to="/services" className="hover:text-slate-600">Services</Link>
                        <Link to="/Projects" className="hover:text-slate-600">Projects</Link>
                        <Link to="/Products" className="hover:text-slate-600">Products</Link>
                        <Link to="/career" className="hover:text-slate-600">Careers</Link>
                        <Link to="/contactus" className="hover:text-slate-600">Contact Us</Link>
                    </div>

                    {/* Footer Locatoin */}
                    <div className="w-full flex flex-col lg:flex-row justify-between py-9">
                        {
                        footerData ?. map((item, index) => (
                            <div className="w-[100%] lg:w-[50%] pr-5"
                                key={index}>
                                <h2 className="font-bold text-[#7B0005] text-[16px] lg:text-[21px]">
                                    {
                                    item ?. branch_name
                                }</h2>
                                <ul className="py-3 list-none">
                                    <li className="py-1 flex">
                                        <div className="w-[25px] h-[25px] flex items-center mr-4">
                                            <MdLocationPin className="text-[21px]"/>
                                        </div>

                                        {
                                        item ?. address
                                    }</li>


                                    <ul className="flex items-center">
                                        <span><MdLocalPhone className="text-[21px] mr-4 justify-center"/></span>
                                        <div className="flex flex-col">
                                            {
                                            item ?. phone1 && (

                                                <li className="py-1">
                                                    <Link to={
                                                            `tel:${
                                                                item ?. phone1
                                                            }`
                                                        }
                                                        target="_blank"
                                                        className="flex hover:text-slate-800">
                                                        {
                                                        item ?. phone1
                                                    }</Link>
                                                </li>
                                            )
                                        }
                                            {
                                            item ?. phone2 && (
                                                <li className="py-1">
                                                    <Link to={
                                                            `tel:${
                                                                item ?. phone3
                                                            }`
                                                        }
                                                        target="_blank"
                                                        className="hover:text-slate-800">
                                                        {
                                                        item ?. phone2
                                                    }</Link>
                                                </li>
                                            )
                                        }
                                            {
                                            item ?. phone3 && (
                                                <li className="py-1">
                                                    <Link to={
                                                            `tel:${
                                                                item ?. phone3
                                                            }`
                                                        }
                                                        target="_blank"
                                                        className="hover:text-slate-800">
                                                        {
                                                        item ?. phone3
                                                    }</Link>
                                                </li>
                                            )
                                        }
                                            {
                                            item ?. phone4 && (
                                                <li className="py-1">
                                                    <Link to={
                                                            `tel:${
                                                                item ?. phone4
                                                            }`
                                                        }
                                                        target="_blank"
                                                        className="hover:text-slate-800">
                                                        {
                                                        item ?. phone4
                                                    }</Link>
                                                </li>
                                            )
                                        } </div>
                                    </ul>


                                    <li className="py-1">
                                        <Link to={
                                                `mailto:${
                                                    item ?. email
                                                }`
                                            }
                                            target="_blank"
                                            className="flex hover:text-slate-800"><MdEmail className="text-[21px] mr-4"/> {
                                            item ?. email
                                        } </Link>
                                    </li>
                                    <li className="py-1">
                                        <Link to={
                                                `https://${
                                                    item ?. website
                                                }`
                                            }
                                            target="_blank"
                                            className="flex hover:text-slate-800"><TbWorld className="text-[21px] mr-4"/> {
                                            item ?. website
                                        } </Link>
                                    </li>
                                </ul>

                            </div>
                        ))
                    }


                        {/* Footer Map */}
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.3199467147765!2d85.3151655760859!3d27.707406125487747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1996e42f4543%3A0x47c87c04d34cf86b!2sOnviro%20Tech%20P.Ltd.!5e0!3m2!1sen!2snp!4v1686545604374!5m2!1sen!2snp"
                            style={
                                {width: "100%"}
                            }
                            className="h-[301px] lg:h-[375px]"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>


                    </div>
                </div>
            </div>

            {/* Foot */}
            <div className="w-full flex justify-center gap-[5px] bg-[#7B0005] py-3 text-[#fff] px-[20px]">
                <p>Copyrights 2023. © All rights reserved by
                </p>
                <Link to="/" className="font-semibold">
                    Onviro Tech Pvt. Ltd.</Link>
            </div>
        </>
    )
}


export default Footer
