import React, {useEffect, useState} from "react";
import {ContactImage} from "../assets";
import {Button, ContactCard, ContactUsDropDown, MetaDecorator} from "../components";
import {itRequirements} from "../utils/itRequirements";
import "../styles/style.css";
import axiosBaseURL from "../baseUrl";
import {useDispatch} from "react-redux";
import {changeNavState} from "../reduxStore/navSlice";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = ({footerInfoData}) => {
    const dispatch = useDispatch();
    // Setting the nav state
    useEffect(() => {
        dispatch(changeNavState(""));
    }, []);

    const [checkedId, setCheckedId] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState();
    const [contents, setContents] = useState("");

    // Handle Checkbox
    const handleCheckBox = (id) => {
        if (checkedId.length > 0) {
            const isAlreadyChecked = checkedId.includes(id);
            if (isAlreadyChecked) {
                const modifiedArray = checkedId.filter((item) => item !== id);
                setCheckedId(modifiedArray);
            } else {
                setCheckedId([
                    ...checkedId,
                    id
                ]);
            }
        } else {
            setCheckedId([
                ...checkedId,
                id
            ]);
        }
    };

    // Handle Submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            name,
            email,
            phone: contact,
            contents,
            service: checkedId.toString()
        };

        try {
            await axiosBaseURL.post("api/contact/store", formData).then((res) => {
              toast.success("Submitted Successfully")
                setName("");
                setEmail("");
                setContact("");
                setContents("");
                setCheckedId("");
            });
        } catch (error) {
            console.log(error);
            toast.error("Please Fill All Fields")
        }
    };

    return (
        <>
            <MetaDecorator title="Contact Us"
                titleA={
                    footerInfoData ?. meta_title ? footerInfoData ?. meta_title : ""
                }
                keywords={
                    footerInfoData ?. meta_keywords ? footerInfoData ?. meta_keywords : ""
                }
                description={
                    footerInfoData ?. meta_description ? footerInfoData ?. meta_description : ""
                }
                ogtitle={
                    footerInfoData ?. og_title ? footerInfoData ?. og_title : ""
                }
                ogdescription={
                    footerInfoData ?. og_descritpion ? footerInfoData ?. og_descritpion : ""
                }
                imageUrl={
                    footerInfoData ?. og_image ? footerInfoData ?. og_image : ""
                }/>

            <div className="mb-[40px]">
                {/*..top Section...*/}
                <div className="bg-backgroundColor px-[40px] py-[60px] w-full flex justify-evenly ">
                    {/*...Contact Us Form...*/}
                    <div className="w-[490px] ">
                        <h1 className="font-bold text-red text-7xl">Contact Us</h1>

                        <form onSubmit={handleSubmit}
                            className="w-full lg:w-[490px] flex flex-col gap-[20px] mt-[30px] ">
                            <label className="w-full flex flex-col items-start gap-[5px]">
                                <p className="font-bold">Full Name</p>
                                <input placeholder="What's your name?"
                                    value={name}
                                    onChange={
                                        (e) => setName(e.target.value)
                                    }
                                    className="w-full placeholder:text-dark placeholder:opacity-[40%] border border-b-dark border-t-0 border-l-0 border-r-0 px-[10px] py-[6px] outline-none bg-transparent "/>
                            </label>

                            <label className="w-full flex flex-col items-start gap-[5px]">
                                <p className="font-bold">Email</p>
                                <input placeholder="Eg. johndoe@gmail.com"
                                    value={email}
                                    onChange={
                                        (e) => setEmail(e.target.value)
                                    }
                                    className="w-full placeholder:text-dark placeholder:opacity-[40%] border border-b-dark border-t-0 border-l-0 border-r-0 px-[10px] py-[6px] outline-none bg-transparent "/>
                            </label>

                            <label className="w-full flex flex-col items-start gap-[5px]">
                                <p className="font-bold">Contact Number</p>
                                <input placeholder="9851033907"
                                    value={contact}
                                    onChange={
                                        (e) => setContact(e.target.value)
                                    }
                                    className="w-full placeholder:text-dark placeholder:opacity-[40%] border border-b-dark border-t-0 border-l-0 border-r-0 px-[10px] py-[6px] outline-none bg-transparent "/>
                            </label>

                            <label className="w-full flex flex-col items-start gap-[5px]">
                                <p className="font-bold">Select your IT requirements</p>
                                <ContactUsDropDown itRequirements={itRequirements}
                                    handleCheckBox={handleCheckBox}
                                    checkedId={checkedId}/>
                            </label>

                            <label className="w-full flex flex-col items-start gap-[5px]">
                                <p className="font-bold">Your Message</p>
                                <textarea placeholder="Write your message"
                                    value={contents}
                                    onChange={
                                        (e) => setContents(e.target.value)
                                    }
                                    className="w-full h-[100px] placeholder:text-dark placeholder:opacity-[40%] border border-b-dark border-t-0 border-l-0 border-r-0 px-[10px] py-[6px] outline-none bg-transparent "/>
                            </label>
                            <div className="w-full flex justify-end">
                                <Button btnName="Submit"/>
                            </div>
                        </form>
                        <ToastContainer />
                    </div>

                    <div className="hidden lg:block">
                        <img src={ContactImage}
                            className="h-[700px] object-contain"
                            alt="contactUsImage"/>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-evenly gap-[30px] px-[40px] py-[60px]">
                    {/*....Map section...*/}
                    <div className="w-full lg:w-[870px] ">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.3199467147765!2d85.3151655760859!3d27.707406125487747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1996e42f4543%3A0x47c87c04d34cf86b!2sOnviro%20Tech%20P.Ltd.!5e0!3m2!1sen!2snp!4v1686545604374!5m2!1sen!2snp"
                            style={
                                {width: "100%"}
                            }
                            className="h-[301px] lg:h-[455px]"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    {/*....Contact Card....*/}
                    <div>
                        <ContactCard footerInfoData={footerInfoData}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
