import React from "react";

const LogoDesignCard = ({ logo, logoName }) => {
  return (
    <div className="group hover:bg-backgroundColor px-[10px] py-[10px] flex flex-col items-center justify-evenly gap-[10px] text-center h-[300px] border border-purple rounded-[5px] ">
      <img src={logo} className="w-[200px] h-[100px] object-contain" alt="" />
      <p className="text-dark font-bold text-lg">{logoName}</p>
    </div>
  );
};

export default LogoDesignCard;
