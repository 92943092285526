import React, { useEffect, useState } from "react";
import {
  AboutPageSlider,
  Button,
  CareerPosition,
  CeoFounder,
  MetaDecorator,
  OurTeam,
  PageTopComponent,
  SecondAboutPageSlider,
} from "../components";
import { FaAngleRight, FaChevronDown } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import axiosBaseURL, { baseUrl } from "../baseUrl";

const AboutUsPage = ({aboutData, homeInfoData}) => {
  const aboutTopData = aboutData?.data;
  const dispatch = useDispatch();
 // Setting the nav state
 useEffect(() => {
  dispatch(changeNavState("about-us"));
}, []);

 {/* Our Story API */} 
 const [storyData, setStoryData] = useState(null);  
 useEffect(() => {
   axiosBaseURL.get("api/story", {                     
   }).then((res) => {
    setStoryData(res.data.data);
  
   })
   .catch((err) => console.log(err));
 }, []);

  {/* Faq API */} 
  const [faqData, setFaqData] = useState(null);  
  useEffect(() => {
    axiosBaseURL.get("api/faq/list", {                     
    }).then((res) => {
      setFaqData(res.data.data);
   
    })
    .catch((err) => console.log(err));
  }, []);

{/* Mission & Vision API */} 
const [featureData, setFeatureData] = useState(null);  
useEffect(() => {
  axiosBaseURL.get("api/feature/list").then((res) => {
  
    setFeatureData(res.data.data);
 
  })
  .catch((err) => console.log(err));
}, []);
 
const [loading, setLoading] = useState(false);
const [careerList, setCareerList] = useState([]);

// Fetch Career list
const fetchCareerList = async () => {
  try {
    setLoading(true);
    await axiosBaseURL.get(`api/career/list`).then((res) => {
     
      if (res?.data?.data?.length > 0) {
        setLoading(false);
        setCareerList(res.data.data);
        return;
      }
    });
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

useEffect(() => {
  fetchCareerList();
}, []);
  const [isActive, setIsActive] = useState(true);
  const [activeIndex, setActiveIndex] = useState(1);
 
  return (
    <div>
  <MetaDecorator      
        title="About Us"
        titleA={aboutTopData?.meta_title ? aboutTopData?.meta_title : ""}
        keywords={aboutTopData?.meta_keywords ? aboutTopData?.meta_keywords : ""}
        description={aboutTopData?.meta_description ? aboutTopData?.meta_description : ""}     
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
      
      {/*...About Us...*/}
      <div className="w-full">
        <PageTopComponent
          title={aboutTopData?.title}
          subtitle={aboutTopData?.subtitle}
          description={aboutTopData?.description}
          image={baseUrl + aboutTopData?.image}
        />
      </div>
      <div className="w-full py-5">
        <AboutPageSlider />
        <SecondAboutPageSlider />
      </div>
      {/* Our story Section */}
      <div className="w-full px-[11px] flex justify-evenly px-4 lg:px-40 py-5 lg:py-8">
        <div className="w-full flex-col lg:flex-row flex justify-between py-1 lg:pt-8">
          <div className="w-full lg:w-1/2 pr-[0px] lg:pr-[6%] py-1 pt-[0%] lg:pt-[3%]">
            <p className="text-start pb-8">About Us / Our Story</p>
            <h2 className="font-semibold text-[#7B0005] text-xl lg:text-4xl">
              {storyData?.title}
            </h2>
            <p style={{ lineHeight: "29px" }} className="py-8">
             {storyData?.description}
            </p>
          </div>
          <div className="w-full lg:w-[681px] flex items-center">
            {/* Our story Details */}
            <div className="w-full flex flex-col">
              <div className="w-full py-3 border-2 border-red rounded-[5px]">
                <div className="accordion px-[9%] py-[7%]">
                  {faqData?.map((item, index) => (
                    <div
                      className="accordion-item py-[15px] border-b border-red"
                      onClick={() => {
                        setActiveIndex(index);
                        if (activeIndex === index && isActive) {
                          setIsActive(false);
                        } else {
                          setIsActive(true);
                        }
                      }}
                      key={index}
                    >
                      <div
                        className="accordion-title flex justify-between cursor-pointer"
                        onClick={() => setIsActive(!isActive)}
                      >
                        <div>
                          <p
                            className={`${
                              isActive && activeIndex === index
                                ? "font-bold text-red text-[21px]"
                                : "font-semibold text-[17px]"
                            }`}
                          >
                            {item?.title}
                          </p>
                        </div>
                        <div>
                          {isActive && activeIndex === index ? (
                            <FaChevronDown
                              className={`${
                                isActive && activeIndex === index
                                  ? "font-bold text-red text-[21px]"
                                  : "font-semibold text-[21px]"
                              }`}
                            />
                          ) : (
                            <FaAngleRight
                              className={`${
                                isActive && activeIndex === index
                                  ? "font-bold text-red text-[21px]"
                                  : "font-semibold text-[21px]"
                              }`}
                            />
                          )}
                        </div>
                      </div>
                      {isActive && activeIndex === index && (
                        <div className="accordion-content">
                          <p style={{ lineHeight: "29px" }} dangerouslySetInnerHTML={{ __html: item?.description }} />
                        </div>
                      )}{" "}
                    </div>
                  ))}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Our Mission & Our Vision Section */}
      {
        featureData?.length > 0 && (
          <div className="flex flex-col lg:flex-row my-8">
          <div className="w-full lg:w-[50%] px-[11px] flex justify-evenly px-4 lg:pl-40 py-5 lg:py-8 bg-red">
            <div className="w-full flex justify-between py-1 lg:pt-8">
              <div className="w-full pr-[0px] lg:pr-[3%] py-1 pt-[0%] lg:pt-[3%]">
                <h2 className="font-semibold text-[#fff] text-xl lg:text-4xl">
                {featureData[0]?.title}
                </h2>
                <p style={{ lineHeight: "29px" }} className="py-5 text-[#fff]" dangerouslySetInnerHTML={{ __html: featureData[0]?.description }}/>
                      
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[50%] px-[11px] flex justify-evenly px-4 lg:pr-40 py-5 lg:py-8 bg-[#371E2B]">
            <div className="w-full flex justify-between py-1 lg:pt-8">
              <div className="w-full pr-[0px] lg:pl-[3%] py-1 pt-[0%] lg:pt-[3%]">
                <h2 className="font-semibold text-[#fff] text-xl lg:text-4xl">
                {featureData[1]?.title}
                </h2>
                <p style={{ lineHeight: "29px" }} className="py-5 text-[#fff]" dangerouslySetInnerHTML={{ __html: featureData[1]?.description }} />             
                
              </div>
            </div>
          </div>
        </div>
        )
      }
   
      {/* Message From CEO */}
      <div className="w-full pt-5">
        <CeoFounder />
      </div>
      {/* Our Management Team */}
      <div className="w-full lg:pt-5">
      <div className="w-full items-center px-[20px] lg:px-40 pb-[0px] lg:pb-[21px]">
                    <p className="text-center pb-8">About Us / The Team</p>
                    <p className="font-semibold text-[#7B0005] text-xl lg:text-4xl text-center">
                        Meet the team
                    </p>

                </div>
        <OurTeam />
      </div>


      {/*...Lower section...*/}
      <div className="w-full flex flex-col items-center gap-[20px] px-[20px] lg:px-globalPadding py-[80px]">
        <p className="text-center text-dark">About Us / Careers</p>
        <p className="text-red text-2xl font-bold text-center">Join the team</p>

        {/*...Job Openings Positions...*/}
        <div className="w-full lg:w-[700px] flex flex-col gap-[20px] ">
        {careerList?.length > 0 &&
            careerList?.map((item, index) => (
              <CareerPosition
                key={index}
                jobTitle={item?.post}
                vacancyNumber={item?.opening_number}
                slug={item?.slug}
              />
            ))}
        </div>

        {/*...Didn't find Preferred position...*/}
        <div className="flex flex-col items-center gap-[20px] mt-[50px] ">
          <p className="text-red text-lg">
            Didn’t find your preferred position?
          </p>
          <div className="w-max">
            <Button btnName="Drop your CV" link="/drop-cv"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
