import { BiCartAlt, BiDonateBlood } from "react-icons/bi";
import { IoLogoSteam, IoMdApps } from "react-icons/io";
import { DiWebplatform } from "react-icons/di";
import { SiGoogledomains } from "react-icons/si";
import { MdManageAccounts } from "react-icons/md";

export const serviceList = [
  {
    id: 1,
    slug: "website-development",
    title: "Website Development",
    featureImage: <BiCartAlt />,
    description:
      "Crafting user-centric websites, Onviro Tech blends creativity and functionality, providing a seamless online experience that aligns with clients' brand and objectives. ",
    
  },
  {
    id: 2,
    slug: "mobile-applications",
    title: "Mobile Applications Development",
    featureImage: <IoMdApps />,
    description:
      "We specialize in multi platform Mobile app development, we build mobile application for Android and IOS platform. We develop professional and affordable mobile app in Nepal.",
  },
  {
    id: 3,
    slug: "it-consultation",
    title: "IT Consultation",
    featureImage: <MdManageAccounts />,
    description:
      "Onviro Tech's IT consultation services provide strategic insights, helping clients nevigate the evolving tech landscape, make informed decisions, and optimize their digital initiatives for business success.",
  },
  {
    id: 4,
    slug: "offshore-team-management",
    title: "Offshore Team Management",
    featureImage: <BiDonateBlood />,
    description:
      "Onviro Tech offers efficient offshore team management, providing skilled professionals for diverse projects, contributing to cost-effective solutions and streamlined project execution.",
  },
  {
    id: 5,
    slug: "software-solutions",
    title: "Software Solutions",
    featureImage: <IoLogoSteam />,
    description:
      "Onviro Tech delivers cutting-edge software solutions for Jewellery Management, Restaurant Management, Online Billing. Software and custom software that you need. ",
  },
  {
    id: 6,
    slug: "domain-hosting",
    title: "Domain & Hosting",
    featureImage: <DiWebplatform />,
    description:
      "Onviro Tech ensures reliable domain services and hosting solutions, guaranteeing secure and efficient online presence for businesses, with a focus on performance and scalability.",
  },

  {
    id: 7,
    slug: "domain-registrations",
    title: "Domain Registration",
    featureImage: <SiGoogledomains />,
    description:
      "Molestie purus nisl tempus purus aliquet tempor felis id vel. Morbi purus et neque vulputate dolor euismod sit eget mauris. Quis diam habitant massa faucibus adipiscing cras mauris. Fames in sed fermentum tincidunt fermentum diam tempus vel. Quam quam nunc nullam lacinia tortor.Odio lacus tincidunt justo sit egestas amet. Aliquam vitae facilisi lectus nisl aliquet sagittis eget. Molestie at volutpat eget in iaculis mauris. Quis at porta cursus odio aliquam donec dict.",
  },
  {
    id: 8,
    slug: "generative-ai",
    title: "Generative AI Solution",
    featureImage: <SiGoogledomains />,
    description:
      "Generative AI can create unique content, optimize product design, automate decision-making and fraud detection, improve customer service and personalize the customer experience. At Dlytica, we provide customized solutions, including consulting, algorithm development, training, and support, tailored to the unique requirements on your own data.",
  },

];
export const aiList = [
  {
    id: 1,
    title: "Discovery and Assessment",
    description:
      "By closely collaborating with our clients, we gain a deep understanding of their business requirements, including their objectives, target audience, and current content. This enables us to pinpoint the most suitable Generative AI solution for their unique use case.",
  },
  {
    id: 2,
    title: "Model Recommendation and Training",
    description:
      "Utilizing cutting-edge deep learning algorithms, we train our Generative AI models on meticulously preprocessed data. This enables us to develop models capable of generating human-like content that is virtually indistinguishable from content produced by humans",
  },
  {
    id: 3,
    title: "Model Optimization and Fine Tuning",
    description:
      "Our commitment to excellence drives us to continuously enhance the accuracy and performance of our Generative AI models. Through iterative optimization, we fine-tune the models based on valuable user feedback and integrate the latest advancements in deep learning technologies.",
  },
  {
    id: 4,
    title: "Deployment and Ongoing Support",
    description:
      "We implement our Generative AI solutions in a live production environment, and our dedicated support team ensures their consistent delivery of high-quality content. Additionally, we closely monitor the performance of our models and implement necessary updates to ensure they continuously align with your evolving requirements.",
  },


];

