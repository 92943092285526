import React, { useEffect, useState } from "react";
import { MetaDecorator, PageTopComponent, ProjectCard } from "../components";
import axiosBaseURL, { baseUrl } from "../baseUrl";

const MobileApplication = ({homeInfoData}) => {
  const [mobileAppData, setMobileAppData] = useState([])

  useEffect(() => {
    axiosBaseURL.get(`api/service/app/details`).then((res) => {
     
        setMobileAppData(res.data);
    }).catch((err) => console.log(err));
}, []);
const appTopData = mobileAppData?.Similar_Service;
  return (
    <div>
            <MetaDecorator      
        title="Mobile Application Development"
        titleA={mobileAppData?.meta_title ? mobileAppData?.meta_title : ""}
        keywords={mobileAppData?.meta_keywords ? mobileAppData?.meta_keywords : ""}
        description={mobileAppData?.meta_description ? mobileAppData?.meta_description : ""}
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
    
      <PageTopComponent
        image={mobileAppData?.image}
        title={mobileAppData?.title}
        subtitle={mobileAppData?.summary}
        description={mobileAppData?.description}
        />

      {/*...Projects....*/}
      <div className="py-[70px] px-[20px] lg:px-40">
        <p className="text-red text-center lg:text-start font-bold text-3xl">
          Some Mobile Application developed by us
        </p>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-[51px] py-[50px] ">
          {appTopData?.length > 0 &&
            appTopData.map((item, index) => (
              <ProjectCard
                key={index}
                companyName={item?.title}
                logo={baseUrl + item?.image}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default MobileApplication;
