export const bloodBankFeaturesData = [
  {
    name: "Application Dashboard",
  },
  {
    name: "Tests & Reports",
  },
  {
    name: "Donation Camp Management",
  },
  {
    name: "Adverse Reactions Management",
  },
  {
    name: "Donor Management",
  },
  {
    name: "Blood Issuing & Billing",
  },
  {
    name: "Provided Donation Camp Management",
  },
  {
    name: "Barcode/Component Labels Management",
  },
  {
    name: "Rejected Candidate Management",
  },
  {
    name: "Blood Requests Management",
  },
  {
    name: "Donor Test Result Management",
  },
  {
    name: "Blood Component Management",
  },
  {
    name: "Existing Components Management",
  },
  {
    name: "Blood Bank Management",
  },
  {
    name: "Blood Components Expiry Management",
  },
  {
    name: "Stock Management",
  },
  {
    name: "Low Stock Management",
  },
  {
    name: "Blood Component Timeline",
  },
  {
    name: "Referrer and Organizer Management",
  },
  {
    name: "Donor Cards Management",
  },
  {
    name: "Donation History and Taken Blood History",
  },
  {
    name: "Eligible Donors and SMS Sending",
  },
  {
    name: "Task Management",
  },
  {
    name: "SMS Management",
  },
  {
    name: "Ledger & Transactions (Simple Record Keeping)",
  },
  {
    name: "Expense Management (Simple Expense Keeping)",
  },
  {
    name: "Purchase Management (Simple Purchases Keeping)",
  },
  {
    name: "Equipments and Maintenances",
  },
  {
    name: "Blood Requests Management",
  },
  {
    name: "Cash Management",
  },
  {
    name: "Contacts Management",
  },
  {
    name: "Duty & Leave Management",
  },
  {
    name: "Duty Roster Management",
  },
  {
    name: "Role/Permission Management",
  },
  {
    name: "User/Employee Management",
  },
  {
    name: "Salary Management",
  },
  {
    name: "Salary Review Management",
  },
  {
    name: "Advance Payment Management",
  },
  {
    name: "Payroll Management",
  },
  {
    name: "EPF & CIT Management",
  },
  {
    name: "Visual Trends & Stats",
  },
  {
    name: "In-Depth Exporting to/Importing from Microsoft Excel",
  },
  {
    name: "Complains/Appraisals Management",
  },
  {
    name: "Monthly/Yearly/All-Time Detailed Reports",
  },
  {
    name: "Database Management",
  },
  {
    name: "Shortcuts Management",
  },
  {
    name: "User Activities Logging",
  },
  {
    name: "Extra Features",
  },
];
