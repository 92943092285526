import {
  ApplicationDevImg,
  BloodBankImg,
  Restro,
  eTender3,
  jwelleryFeature,
  mobileApplication,
} from "../assets";

export const productsListData = [
  {
    title: "Blood Bank Management Software (.net/SQL)",
    link: "/services/blood-bank-software",
    image: BloodBankImg,
    id: 1,
  },
  {
    title: "Restro Byte",
    link: "/services/restrobyte",
    image: Restro,
    id: 2,
  },
  {
    title: "Mobile Application Development",
    link: "/services/mobile-applications",
    image: mobileApplication,
    id: 3,
  },
  {
    title: "e-Tender Application",
    link: "/services/e-Tender-Application",
    image: eTender3,
    id: 3,
  },
  {
    title: "J-Jewellery Software",
    link: "/services/Jewellery",
    image: jwelleryFeature,
    id: 3,
  },
];
