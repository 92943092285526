import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/style.css";

const ProductsCard = ({ data }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div>
      {" "}
      {/* ProductsCard Section */}
      <div className="w-full">
        <div
          className={`relative`}
          onMouseEnter={() => handleMouseEnter(2)}
          onMouseLeave={handleMouseLeave}
        >
          <img
            src={data?.image}
            className="w-full h-[25vh] object-cover"
            alt="ProductsImg"
          />{" "}
          {hoveredIndex && (
            <Link
              className="flex justify-center"
              style={{ zIndex: 10 }}
              to={data?.link}
            >
              <button
                style={{ background: "#7B0005", zIndex: 10 }}
                className="rounded-[5px] px-6 py-2 text-white text-left absolute bottom-[9%]"
              >
                <p className="border-b-2 border-white text-white">View All</p>
              </button>
            </Link>
          )}
          {hoveredIndex && (
            <div
              style={{ zIndex: 9 }}
              className="w-full h-[25vh] absolute top-0 right-0 bottom-0 left-0 bg-black opacity-[.6]"
            ></div>
          )}
        </div>
        <h5 className="text-lg font-semibold mb-1 pt-3 text-[#0F0A1A]">
          {data?.title}
        </h5>
      </div>
    </div>
  );
};

export default ProductsCard;
