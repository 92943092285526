import React, {useRef} from 'react';
import "../styles/style.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Link} from 'react-router-dom';

const OurClients = ({OurClientsData}) => {

    const sliderRef = useRef(null);
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 7000, // Set slide transition speed (in milliseconds)
        ease: 'ease', // Set slide transition easing effect
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <div className="w-full bg-[#E7DADA] my-8">
            {/* OurClients Title Section */}
            <div className="text-center lg:pt-8">
                <h2 className="font-semibold pt-3 text-[#7B0005] text-xl lg:text-4xl">
                    Trusted By
                </h2>
            </div>
            {/* OurClients slider Section */}
            <div className="w-full flex flex-col justify-between px-4 lg:px-10 py-3 lg:py-5">
                <div className="relative">
                    <Slider {...settings}
                        ref={sliderRef}>
                        {
                        OurClientsData?.map((item, index) => (
                            <div className='w-full px-[21px]'
                                key={index}>
                                <div className='flex justify-center items-center'>
                                    <Link to={item?.url} target='_blank'><img src={
                                                item ?. image
                                            }
                                            alt="brandsImg"
                                            className='w-full h-[90px] object-contain'/>
                                    </Link>

                                </div>
                            </div>
                        ))
                    }
                        
                       </Slider>

                </div>
            </div>
        </div>
    );
};

export default OurClients;
