import { dashboard1, dashboard2, dashboard3 } from "../assets";

export const dashboardData = [
    {
        image: dashboard1,
        title: "Dashboard1"
    },
    {
        image: dashboard2,
        title: "Dashboard2"
    },
    {
        image: dashboard3,
        title: "Dashboard3"
    },
]