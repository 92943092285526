import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Button from "./Button";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosBaseURL from "../baseUrl";

const DropCV = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [resume, setResume] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [githubLink, setGithubLink] = useState("");
const [preferredJobPosition, setPreferredJobPosition] = useState('');

  // Handle file change
  function handleFileChange(event) {
    const file = event.target.files[0];
    setResume(file);
  }
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
 const formData = new FormData();

 formData.append("full_name", fullName)
 formData.append("email", email)
 formData.append("attachment", resume)
 formData.append("salary", expectedSalary) 
 formData.append("github_link", githubLink)
 formData.append("title", preferredJobPosition)
    try {
      await axiosBaseURL.post("api/career/store", formData).then((res) => {
        toast.success("Submitted Successfully")
          setFullName("");
          setEmail("");
          setResume("");
          setExpectedSalary("");
          setGithubLink("");
          setPreferredJobPosition("");
      });
  } catch (error) {
      console.log(error);
      toast.error("Please Fill All Fields")
  }
  
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="w-full mt-[30px] flex flex-col gap-[20px]"
      >
         {/*...Job Position...*/}
         <label className="w-full flex flex-col items-start gap-[5px]">
          <p className="text-dark">
            Job Position<span className="text-red">*</span>
          </p>
          <input
            type="text"
            value={preferredJobPosition}
            placeholder="Enter Preferred Job Position"
            onChange={(e) => setPreferredJobPosition(e.target.value)}
            className="w-full bg-backgroundColor p-[9px] rounded-[6px]"
          />
        </label>

        {/*...Name...*/}
        <label className="w-full flex flex-col items-start gap-[5px]">
          <p className="text-dark">
            Full Name<span className="text-red">*</span>
          </p>
          <input
          value={fullName}
            type="text"
            placeholder="Enter Your Name"
            onChange={(e) => setFullName(e.target.value)}
            className="w-full bg-backgroundColor p-[9px] rounded-[6px]"
          />
        </label>
        {/*....Email....*/}
        <label className="w-full flex flex-col items-start gap-[5px]">
          <p className="text-dark">
            Email<span className="text-red">*</span>
          </p>
          <input
          value={email}
            type="email"
            placeholder="Enter your email address"
            onChange={(e) => setEmail(e.target.value)}
            className="w-full bg-backgroundColor p-[9px] rounded-[6px]"
          />
        </label>
        {/*....Resume....*/}
        <label className="w-full flex flex-col items-start gap-[5px]">
          <p className="text-dark">
            Resume<span className="text-red">*</span>
          </p>
          <label
            htmlFor="upload-resume"
            className="w-max bg-backgroundColor p-[9px] rounded-[6px] text-slate-400 underline underline-offset-8 cursor-pointer"
          >
            {resume ? (
              <div className="flex items-start gap-[5px] w-max border rounded-xl p-[10px] ">
                <p>{resume.name}</p>
                <RxCross2 onClick={() => setResume("")} className="" />
              </div>
            ) : (
              <p>Attach Resume</p>
            )}
          </label>
          <input
            id="upload-resume"
            type="file"
            placeholder="Attach Resume"
            onChange={handleFileChange}
            className="custom-file-input"
          />
        </label>
        {/*...Expected Salary...*/}
        <label className="w-full flex flex-col items-start gap-[5px]">
          <p className="text-dark">Expected Salary</p>
          <input
          value={expectedSalary}
            type="text"
            placeholder="Enter your expected salary range"
            onChange={(e) => setExpectedSalary(e.target.value)}
            className="w-full bg-backgroundColor p-[9px] rounded-[6px]"
          />
        </label>
        {/*....Github Link....*/}
        <label className="w-full flex flex-col items-start gap-[5px]">
          <p className="text-dark">
            GitHub Link<span className="text-red">*</span>
          </p>
          <input
          value={githubLink}
            type="text"
            placeholder="Paste the link here"
            onChange={(e) => setGithubLink(e.target.value)}
            className="w-full bg-backgroundColor p-[9px] rounded-[6px]"
          />
        </label>

        <div className="w-max mt-[10px]">
          <Button type="submit" btnName="Submit Application" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default DropCV;
