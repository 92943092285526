import React, {useRef} from 'react';
import "../styles/style.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

const Testimonials = ({testimonialsData}) => {
    const sliderRef = useRef(null);
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    };

    const goToPreviousSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const goToNextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };
    return (
        <div> {/* Testimonials Section */}
            <div className="w-full mt-2 lg:mt-8">
                <div className="w-full items-center px-[20px] lg:px-[205px] pb-[0px] lg:pb-[21px]">
                    <p className="text-center pt-1 pb-5">05/ Testimonials</p>
                    <p className="font-semibold text-[#7B0005] text-xl lg:text-4xl text-center">
                        Checkout What People Say About Us
                    </p>

                    <div className="w-full py-8 px-[0px] lg:px-[13%]">

                        <Slider {...settings}
                            ref={sliderRef}>
                            {
                            testimonialsData ?. map((item, index) => (
                                <div key={index}>
                                    <div className="w-full h-[400px] flex flex-col items-center justify-center rounded-[5px] border border-[#7B0005] SliderimageSize">
                                        <p className="pt-3 pb-5 px-[11%] text-center font-semibold" dangerouslySetInnerHTML={{ __html: item?.speech.length > 250 ? item?.speech.slice(0,250) + "..." : item?.speech }} /> 
                                        <img src={item?.image}
                                            alt="Blogs"
                                            className="h-[100px] w-[100px] rounded-full"/>
                                        <div className="flex justify-center flex-col detailsa">
                                            <p className="font-bold text-[#7B0005] text-center text-[16px] lg:text-[21px] pt-2">
                                            {item?.name}
                                            </p>
                                            <h5 className="text-lg font-medium pb-3 text-[#0F0A1A] text-sm">{item?.designation}</h5>

                                        </div>
                                    </div>
                                </div>

                            ))
                        }

                       </Slider>
                        <div className='flex justify-end gap-[21px] pt-8'>
                            <button className=" transform -translate-y-1/2 text-white transition duration-500 bg-[#fff] hover:bg-[#E7DADA] rounded-full border border-black w-10 h-10 flex items-center justify-center"
                                onClick={goToPreviousSlide}>
                                <FontAwesomeIcon className='text-[#000]'
                                    icon={faChevronLeft}/>
                            </button>
                            <button className=" transform -translate-y-1/2 text-white transition duration-500 bg-[#fff] hover:bg-[#E7DADA] rounded-full border border-black w-10 h-10 flex items-center justify-center"
                                onClick={goToNextSlide}>
                                <FontAwesomeIcon className='text-[#000]'
                                    icon={faChevronRight}/>
                            </button>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    );
};

export default Testimonials;
