import React from "react";
import { BsPlusCircle } from "react-icons/bs";
import { Link } from "react-router-dom";

const SingleFaq = ({ title,slug,hoverColor }) => {
  return (
    <div className={`group w-full px-4 lg:px-40 lg:px-globalPadding border-2 border-b-red border-t-0 border-l-0 border-r-0 ${hoverColor && hoverColor}`}>
      <Link to={`/services/${slug}`} className="w-full flex items-center justify-between py-8">
        <p className="font-semibold text-lg group-hover:text-xl">{title}</p>
        <BsPlusCircle className="cursor-pointer text-xl group-hover:text-2xl"/>
      </Link>
    </div>
  );
};

export default SingleFaq;
