import React, {useEffect, useState} from 'react'
import {ProjectBannerImg} from '../assets'
import {LogoDesignCard, MetaDecorator, PageTopComponent, ProjectCard} from '../components';
import {useDispatch} from 'react-redux';
import {changeNavState} from '../reduxStore/navSlice';
import axiosBaseURL from '../baseUrl';
import {useSearchParams} from 'react-router-dom';


const ProjectsPage = ({homeInfoData}) => {
    const [active, setActive] = useState(4);
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const query = searchParams.get('mobile-application-development');
    useEffect(() => {
        if (query) {
            setActive(parseInt(query))
        }
    }, [query])


    // Setting the nav state
    useEffect(() => {
        dispatch(changeNavState("projects"));
    }, []);

    { /* Projects API */
    }
    const [tab, setTab] = useState([])
    const [projectData, setProjectData] = useState(null);

    useEffect(() => {
        axiosBaseURL.get("api/category/list").then((res) => {
            setTab(res.data.category);

        }).catch((err) => console.log(err));

        if (query ==  1) {
            axiosBaseURL.get(`api/category/7`).then((res) => {
                setProjectData(res.data.projects);
            }).catch((err) => console.log(err));
        } else {
            axiosBaseURL.get(`api/project/list`).then((res) => {
                setProjectData(res.data.projects);
            }).catch((err) => console.log(err));
        }

    }, [query]);

    const handleClick = async (index, id) => {
        setActive(index);
        if (index === 4) {
            axiosBaseURL.get(`api/project/list`).then((res) => {
                setProjectData(res.data.projects);
            }).catch((err) => console.log(err));
        } else {
            axiosBaseURL.get(`api/category/${id}`).then((res) => {
                setProjectData(res.data.projects);
            }).catch((err) => console.log(err));
        }
    }

    return (
        <div>
            <MetaDecorator title="Our Projects" titleA="Our Projects" keywords="Our Projects" description="Our Projects"
                ogtitle={
                    homeInfoData ?. og_title ? homeInfoData ?. og_title : ""
                }
                ogdescription={
                    homeInfoData ?. og_descritpion ? homeInfoData ?. og_descritpion : ""
                }
                imageUrl={
                    homeInfoData ?. og_image ? homeInfoData ?. og_image : ""
                }/>
         
            {/*...Title top...*/}
            <div className='w-full'>
                <PageTopComponent title="Our projects" subtitle="Innovative Solutions, Transformative Results: Explore Our Projects Portfolio" description="Our tech company has successfully completed a diverse portfolio of projects, showcasing our expertise and versatility in the industry. From developing custom software solutions for startups to implementing large-scale enterprise applications for established businesses, our projects span across various sectors such as healthcare, finance, e-commerce, and logistics. We take pride in delivering high-quality solutions that meet our clients' unique requirements, leveraging cutting-edge technologies and adhering to industry best practices. With a focus on innovation, reliability, and user experience, our projects demonstrate our commitment to helping businesses thrive in the digital age."
                    image={ProjectBannerImg}/>
            </div>
            <div className="w-full px-[11px] flex flex-col justify-between px-4 lg:px-40 py-5 lg:py-8">
                <div className="flex justify-start pt-8">
                    <ul className="flex gap-3 flex-wrap">
                        {
                        tab ?. map((item, index) => (
                            <div key={index}>
                                <li>
                                    <button className={
                                            `rounded-[5px] px-2 py-2 text-white hover:opacity-90 text-left ${
                                                active === index && 'bg-[#E7DADA]'
                                            }`
                                        }
                                        onClick={
                                            () => handleClick(index, item ?. id)
                                    }>
                                        <p className={
                                            `w-max pr-[30px] ${
                                                active === index && 'border-b-2 border-black'
                                            } text-black text-sm`
                                        }>
                                            {
                                            item ?. title
                                        }</p>
                                    </button>
                                </li>
                            </div>


                        ))
                    }
                        <li>
                            <button onClick={
                                    () => handleClick(4, 4)
                                }
                                className={
                                    `rounded-[5px] px-2 py-2 text-white hover:opacity-90 text-left ${
                                        active === 4 && 'bg-[#E7DADA]'
                                    }`
                            }>
                                <p className={
                                    `w-[75px] ${
                                        active === 4 && 'border-b-2 border-black'
                                    } text-black text-sm`
                                }>View all</p>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className=''>
                    {
                    (projectData ?. length > 0) && (
                        <div className="w-full mt-0 lg:my-5">

                            <div className="grid grid-cols-1 md:grid-cols-4 gap-[51px] items-center py-[11px]">
                                {
                                projectData ?. length > 0 && projectData.map((item, index) => (
                                    <div key={index}>
                                        {
                                        item.category.id === 6 ? (
                                            <LogoDesignCard logo={
                                                    item ?. image
                                                }
                                                logoName={
                                                    item ?. title
                                                }/>
                                        ) : (
                                            <ProjectCard companyName={
                                                    item ?. title
                                                }
                                                logo={
                                                    item ?. image
                                                }
                                                link={
                                                    item ?. url
                                                }/>
                                        )
                                    } </div>
                                ))
                            } </div>

                        </div>
                    )
                } </div>
            </div>
        </div>
    )
}

export default ProjectsPage
