import React, { useEffect, useState } from 'react';
import "../styles/style.css";
import {roka} from '../assets';
import axiosBaseURL from '../baseUrl';


const CeoFounder = () => {
{/* CEO API */} 
const [ceoData, setCeoData] = useState(null);  
useEffect(() => {
  axiosBaseURL.get("api/founder/message", {                     
  }).then((res) => {
    setCeoData(res.data.data);
 
  })
  .catch((err) => console.log(err));
}, []);

    return (
        <div> {/* CEO Title Section */}
            <div className="w-full px-[11px] lg:px-40 mt-2 lg:mt-8">
                <div className="w-full items-center px-[20px] lg:px-[205px] pb-[0px] lg:pb-[21px]">
                    <p className="font-semibold text-[#7B0005] text-xl lg:text-4xl text-center">
                        Hear from our CEO/Founder
                    </p>

                </div>

                <div className="w-full flex justify-evenly">

                    <div className="w-full flex flex-col lg:flex-row justify-between py-1 lg:py-3">
                        <div className="w-full lg:w-1/2 flex items-center">
                            {/* CEO Image */}
                            <div className="w-full my-2 flex justify-center">
                                <img src={ceoData?.image}
                                    className="lg:max-w-[401px] max-w-[351px] lg:min-w-[401px] min-w-[351px] lg:max-h-[401px] lg:min-h-[401px] min-h-[351px] max-h-[351px] rounded-full object-cover"
                                    alt="AboutUsImg"/>
                            </div>
                        </div>
                        {/* CEO Discription */}
                        <div className="w-full lg:w-1/2 pl-[0px] lg:pl-[5%] py-1 lg:py-2">
                            <h2 className="font-semibold text-[#7B0005] text-2xl">
                               {ceoData?.name}
                            </h2>
                            <p className="text-start font-semibold text-lg" dangerouslySetInnerHTML={{ __html: ceoData?.designation }}></p>
                            <p style={
                                    {lineHeight: "29px"}
                                }
                                className="py-5" dangerouslySetInnerHTML={{ __html: ceoData?.speech }}>                              
                            </p>


                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default CeoFounder;
