import React from "react";
import { Link } from "react-router-dom";
import "../styles/style.css";
import Button from "./Button";
import { baseUrl } from "../baseUrl";

const AboutUs = ({aboutData}) => {
  return (
    <div>
      {/* AboutUs Section */}
    
        <div className="w-full flex justify-evenly px-4 lg:px-40 py-5 lg:py-8">

          <div className="w-full flex-col lg:flex-row flex justify-between py-1 lg:py-3">
            <div className="w-full lg:w-1/2 pr-[0px] lg:pr-[5%] py-1 lg:py-2">
              <p className="text-start pb-5">02/ About Us</p>
              <h2 className="font-semibold text-[#7B0005] text-xl lg:text-4xl">
                {aboutData?.title}
              </h2>
              <p style={{
              lineHeight: "29px"
            }} className="pt-8 pb-3" dangerouslySetInnerHTML={{ __html: aboutData?.description }}>
              </p>
              {/* AboutUs Button */}             
              <div className="w-max">   
              <Link to={'/aboutus'} >             
              <Button btnName="Know More" />    
              </Link>          
              </div>
             

            </div>
            <div className="w-full lg:w-1/2 flex items-center">
              {/* AboutUs Image */}
              <div className="w-full flex flex-col">
                <img
                  src={baseUrl + aboutData?.image}
                  className="w-full py-3 object-cover"
                  alt="AboutUsImg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
   
  );
};

export default AboutUs;
