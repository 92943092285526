export const technologyUsed = [
  {
    title: "Frontend Desktop Application",
    info: [
      {
        title: "",
        data: ["Microsoft C#"],
      },
    ],
  },
  {
    title: ".net Framework",
    info: [
      {
        title: "",
        data: ["4.5 or more"],
      },
    ],
  },
  {
    title: "Backend ",
    info: [
      {
        title: "",
        data: ["Microsoft SQL Server 2016 or more"],
      },
    ],
  },
  {
    title: "Windows Service",
    info: [
      {
        title: "",
        data: ["Microsoft C#"],
      },
    ],
  },
  {
    title: "API ",
    info: [
      {
        title: "",
        data: ["Microsoft C#"],
      },
    ],
  },
  {
    title: "iPhone Application",
    info: [
      {
        title: "",
        data: ["Flutter"],
      },
    ],
  },
  {
    title: "Android Application",
    info: [
      {
        title: "",
        data: ["Flutter"],
      },
    ],
  },
];

export const technologyUsedA = [
  {
    title: "Frontend Desktop",
    info: [
      {
        title: "UI/UX",
        data: ["Figma","React Js"],
      },
    ],
  },  
  {
    title: "Backend ",
    info: [
      {
        title: "",
        data: ["Laravel (10.10)"],
      },
    ],
  },

];

export const systemRequirements = [
  {
    title: "Server",
    info: [
      {
        title: "Online",
        data: [
          " Processor Core i5 or more",
          "RAM 8GB or more",
          "Storage: SSD 8 GB or more",
          "NET Server hosting/domain",
          "Internet connection (higher the better)",
          "(our data resides in other’s server)",
        ],
      },
      {
        title: "Offline",
        data: [
          " Processor Core i5 or more",
          " RAM 8GB or more",
          "SSD 256 GB or more (HDD not recommended)",
          "LAN connection to clients",
          "(our data resides in our own server)",
        ],
      },
      {
        title: "In-house (works both offline/online)",
        data: [
          "Unique server IP address",
          "Processor Core i5 or more",
          "RAM 8GB or more",
          " SSD 256 GB or more (HDD not recommended)",
          "LAN connection to clients",
          "Internet connection (higher the better)",
          
        ],
      },
    ],
  },
  {
    title: "Client computer",
    info: [
      {
        title: "Desktop/ Laptop or any combination",
        data: [
          "Processor Core 2 duo or more",
          "RAM 4GB or more",
          "HDD/SSD 128 GB or more",
          "Monitor for related desktop computer",
          "Laptop/Monitor should support 1366x768 pixel resolution which almost every machine supports by default now-a-days",
        ],
      },
    ],
  },
  {
    title: "Laptop",
    info: [
      {
        title:
          "Laptops are used for off-site donor registrations which will be imported in server system",
        data: [],
      },
    ],
  },
  {
    title: "Barcode Printer",
    info: [
      {
        title: "",
        data: [
          "1 for unit number barcode printing",
          "1 for component label printing",
        ],
      },
    ],
  },
  {
    title: "Barcode Reader",
    info: [
      {
        title: "Reading unit number barcodes wherever applicable",
        data: [],
      },
    ],
  },
  {
    title: "Laser Printer",
    info: [
      {
        title: "",
        data: ["Invoice printing","Job card printing","Issue card printing","Immuno-haematology report printing"," Donor card printing","Blood components stock printing","Graphs and trends printing","And more…"],
      },
    ],
  },
];
export const systemRequirementsA = [
  {
    title: "Spend Analysis",
    info: [
      {
        title: "",
        data: [
          " Spend analysis tools help you uncover purchasing trends and identify opportunities for cost savings, performance improvements, and general efficiency gains. You can analyse spending by category, supplier, and more.",
         
        ],
      },
     
    ],
  },
  {
    title: "Supplier Discovery",
    info: [
      {
        title: "",
        data: [
          "Tools for supplier discovery let you search for suppliers that meet your requirements for capabilities, location, supplier diversity, and more.",
         
        ],
      },
     
    ],
  },
  {
    title: "Supplier Information Management",
    info: [
      {
        title: "",
        data: [
          "Supplier Information Software, also known as supplier onboarding, enrollment, or registration, allows you to collect and manage supplier information directly from suppliers, ensuring efficiency and accuracy. This info can range from contact details to certification status and more.",
         
        ],
      },
     
    ],
  },
  {
    title: "eSourcing",
    info: [
      {
        title: "",
        data: [
          " eSourcing software enables you to electronically request bids and proposals from suppliers in real-time.",
         
        ],
      },
     
    ],
  },
  {
    title: "Contract Management",
    info: [
      {
        title: "",
        data: [
          " Contract management software allows you to quickly create contracts using pre-written templates and clause libraries.",
         
        ],
      },
     
    ],
  },
  {
    title: "e-Procurement",
    info: [
      {
        title: "",
        data: [
          "eProcurement software lets users browse catalogues of pre-approved goods and services from approved vendors, create requisitions, and have those requisitions turned into purchase orders (either manually by buyers or automatically when specific criteria are met).",
         
        ],
      },
     
    ],
  },
  {
    title: "e-Invoicing",
    info: [
      {
        title: "",
        data: [
          "With e-Invoicing software, also known as ePayment, you can receive supplier invoices online, boosting efficiency and accuracy.",
         
        ],
      },
     
    ],
  },
  {
    title: "Supplier Management",
    info: [
      {
        title: "",
        data: [
          "Supplier management tools let you monitor and/or rate supplier performance.",
         
        ],
      },
     
    ],
  },
  {
    title: "Combination Solutions",
    info: [
      {
        title: "",
        data: [
          "Procure-to-pay, or P2P, refers to an eProcurement system where purchasing organisations can pay suppliers directly, rather than having to do it through their ERP systems. When these systems allow users to receive vendor bids, they're called source-to-pay solutions.",
         
        ],
      },
     
    ],
  },
  {
    title: "Full-Suite Solutions",
    info: [
      {
        title: "",
        data: [
          "Some software vendors offer most or all of the solutions included in this list. Such comprehensive sets of solutions are called suites.",
         
        ],
      },
     
    ],
  },
];
export const systemRequirementsB = [
  {
    title: "Easy-to-use interface",
    info: [
      {
        title: "",
        data: [
          "The importance of intuitive construction procurement software cannot be overstated. There's no way to make up for the time and money spent training your team on an overly complicated platform.",
         
        ],
      },
     
    ],
  },
  {
    title: "Supplier management",
    info: [
      {
        title: "",
        data: [
          "Procurement software features will be including supplier onboarding functionality to ensure that only authorized suppliers are used for purchases.",
         
        ],
      },
     
    ],
  },
  {
    title: "Budget insight and control",
    info: [
      {
        title: "",
        data: [
          "When budgets are transparent, users are better equipped to make smart purchases. Procurement software should provide authorized personnel with access to real-time data and the ability to report on budgets vs actuals to promote this visibility.",
         
        ],
      },
     
    ],
  },
  {
    title: "Visibility and business intelligence",
    info: [
      {
        title: "",
        data: [
          "Gaining visibility into expenses by spend category is a crucial goal of procurement installations. Your ability to analyses and report on historical and current data depends on understanding how your company spends money and where to make cost cuts.",
         
        ],
      },
     
    ],
  },
  
];
export const systemRequirementsC = [
  {
    title: "Sales/Purchase",
    info: [
      {
        title: "",
        data: [
          "Customer/Supplier Management, Barcode Scanning/Printing, Order Management, Metal Transactions, Advance, Sales / Purchase of Raw Metal/Gems, Sales / Purchase of Fine Jewellery, Digital /Cash/Card/ Credit mode of payments, Invoice Generation, Order Processing and staging, Credit Records and cash receipt, Item tagging and search by tag",
         
        ],
      },
     
    ],
  },
  {
    title: "Karigar",
    info: [
      {
        title: "",
        data: [
          "Karigar Assignment on Orders, Karigar Stocks, Karigar Commission",
         
        ],
      },
     
    ],
  },
  {
    title: "Stock",
    info: [
      {
        title: "",
        data: [
          "Stock Management, Stock Report, Fine Jewellery Stock",
         
        ],
      },
     
    ],
  },
  {
    title: "Loan/Deposit Management",
    info: [
      {
        title: "",
        data: [
          "Loan Issue, Interest calculation and payment, Deposit management, Loan Increment and deduction, Deposit increment and deduction",
         
        ],
      },
     
    ],
  },
  {
    title: "Others",
    info: [
      {
        title: "",
        data: [
          " SMS/What App Features, Database Backup",
         
        ],
      },
     
    ],
  },
  {
    title: "Reports",
    info: [
      {
        title: "",
        data: [
          "Daily Sales Report, Daily entries Report, Loan Report, Cash Flow, Stock, Karigar Records, Export Reports in PDF/Word/Excel/Print, And Many more",
         
        ],
      },
     
    ],
  },
 
];