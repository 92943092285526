import React, {useEffect, useState} from 'react'
import {FacebookIcon, LinkedInIcon, MailIcon} from '../assets'
import "../styles/style.css";
import axiosBaseURL from '../baseUrl';
import {Link} from 'react-router-dom';

const ContactCard = ({footerInfoData}) => {
    { /* ContactInfo API */
    }
    const [contactInfoData, setContactInfoData] = useState([]);
    useEffect(() => {
        axiosBaseURL.get("api/office/list", {}).then((res) => {
            setContactInfoData(res.data.data);
        }).catch((err) => console.log(err));
    }, []);

    return (
        <div className='w-full lg:w-[440px] bg-red flex flex-col gap-[30px] px-[30px] py-[50px] text-white'>

            <div>
                <p className='font-medium text-lg'>
                    Contact Number
                </p>
                <Link to={
                        `tel:${
                        contactInfoData[0] ?. phone1
                        }`
                    }
                    target="_blank">
                    <p className='font-[300] text-[16px] '>
                        {
                        contactInfoData[0] ?. phone1
                    } </p>
                </Link>
            </div>

            <div>
                <p className='font-medium text-lg'>
                    Email
                </p>
                <Link to={
                        `mailto:${
                            contactInfoData[0] ?. email
                        }`
                    }
                    target="_blank">
                    <p className='font-[300] text-[16px] '>
                        {
                        contactInfoData[0] ?. email
                    } </p>
                </Link>
            </div>

            <div>
                <p className='font-medium text-lg'>
                    Join on Our Page
                </p>
                <Link to={
                        footerInfoData ?. facebook_link
                    }
                    target="_blank">
                <p className='font-[300] text-[16px] '>
                    facebook/onvirotech.com
                </p>
                </Link>
            </div>

            <p>
                Let's collaborate and shape the future of technology together!
            </p>

            <div className='flex items-center gap-[20px]'>
                <Link to={
                        footerInfoData ?. facebook_link
                    }
                    target="_blank">
                    <img src={FacebookIcon}
                        className='facebook_icon'/>
                </Link>
                <Link to={
                        `mailto:${
                            contactInfoData[1] ?. email
                        }`
                    }
                    target="_blank">
                    <img src={MailIcon}
                        className='facebook_icon'/>
                </Link>
                <Link to={
                        footerInfoData ?. linkedin_link
                    }
                    target="_blank">
                    <img src={LinkedInIcon}
                        className='facebook_icon'/>
                </Link>
            </div>
        </div>
    )
}

export default ContactCard
