export const itRequirements = [
    {
        id: "1",
        name: "Web Hosting"
    },
    {
        id: "2",
        name: "Dedicated Server"
    },
    {
        id: "3",
        name: "Website Design"
    },
    {
        id: "4",
        name: "Disaster Recover/Backup"
    },
    {
        id: "5",
        name: "Advice & Guidance"
    },
    {
        id: "6",
        name: "Others"
    }
]