import React, {useEffect, useState} from 'react';
import "../styles/style.css";
import axiosBaseURL from '../baseUrl';
import {FaLinkedin} from 'react-icons/fa';
import {Link} from 'react-router-dom';

const OurTeam = () => {
    { /* Team API */
    }
    const [teamData, setTeamData] = useState(null);
    useEffect(() => {
        axiosBaseURL.get("api/team/list", {}).then((res) => {
            setTeamData(res.data.data);

        }).catch((err) => console.log(err));
    }, []);
    return (
        <div> {/* Our Team Title Section */}
            <div className="w-full px-[11px] ">

                <div className="w-full">

                    <div className="w-full flex flex-col lg:flex-row justify-between flex-wrap py-1 lg:py-5">
                        {
                        teamData ?. map((item, index) => (
                            <div className="w-full lg:w-[40%] flex lg:mx-[5%]"
                                key={index}>
                                {
                                index % 2 === 0 ? (
                                    <div className="w-full flex items-center border-y border-red my-5  py-1 lg:py-5">
                                        <div className={`w-full my-2 flex justify-start`}>
                                            <ul className="w-full py-5 px-5">
                                                <li>
                                                    <p className="font-bold text-[#7B0005] text-xl">
                                                        {
                                                        item ?. name
                                                    } </p>
                                                </li>
                                                <li>
                                                    <p className="font-semibold text-[#000] text-lg">
                                                        {
                                                        item ?. designation
                                                    } </p>
                                                </li>
                                                <li>
                                                    <p className="font-semibold text-[#000] text-sm">
                                                        {
                                                        item ?. expertise
                                                    } </p>
                                                </li>
                                                <li>
                                                    <p className="text-[#000] break-all lg:text-lg pt-8">
                                                        <Link to={
                                                                `mailto:${
                                                                    item ?. email
                                                                }`
                                                            }
                                                            target='_blank'>
                                                            {
                                                            item ?. email
                                                        } </Link>
                                                    </p>
                                                </li>
                                                {
                                                (item ?. linkedin) && (
                                                    <li>
                                                        <p className="w-max font-semibold text-[#000] text-lg">
                                                            <Link to={
                                                                    item ?. linkedin
                                                                }
                                                                target='_blank'>
                                                                <FaLinkedin/>
                                                            </Link>
                                                        </p>
                                                    </li>
                                                )
                                            } </ul>
                                        </div>
                                        <div className={`w-full my-2 flex justify-end`}>
                                            <img src={

                                                    item ?. image
                                                }
                                                className="lg:max-w-[151px] max-w-[111px] lg:min-w-[151px] min-w-[111px] lg:max-h-[151px] lg:min-h-[151px] min-h-[111px] max-h-[111px] rounded-full object-cover"
                                                alt="AboutUsImg"/>
                                        </div>

                                    </div>
                                ) : (
                                    <div className="w-full flex items-center border-y border-red my-5 py-1 lg:py-5">
                                        <div className={`w-full my-2 flex justify-start`}>
                                            <img src={

                                                    item ?. image
                                                }
                                                className="lg:max-w-[151px] max-w-[111px] lg:min-w-[151px] min-w-[111px] lg:max-h-[151px] lg:min-h-[151px] min-h-[111px] max-h-[111px] rounded-full object-cover"
                                                alt="AboutUsImg"/>
                                        </div>
                                        <div className={`w-full my-2 flex justify-end`}>
                                            <ul className="w-full py-5 px-5">
                                                <li>
                                                    <p className="font-bold text-[#7B0005] text-xl">
                                                        {
                                                        item ?. name
                                                    } </p>
                                                </li>
                                                <li>
                                                    <p className="font-semibold text-[#000] text-lg">
                                                        {
                                                        item ?. designation
                                                    } </p>
                                                </li>
                                                <li>
                                                    <p className="font-semibold text-[#000] text-sm">
                                                        {
                                                        item ?. expertise
                                                    } </p>
                                                </li>
                                                <li>
                                                    <p className="text-[#000] break-all lg:text-lg pt-8">
                                                        <Link to={
                                                                `mailto:${
                                                                    item ?. email
                                                                }`
                                                            }
                                                            target='_blank'>
                                                            {
                                                            item ?. email
                                                        } </Link>
                                                    </p>
                                                </li>
                                                {
                                                (item ?. linkedin) && (
                                                    <li>
                                                        <p className="w-max font-semibold text-[#000] text-lg">
                                                            <Link to={
                                                                    item ?. linkedin
                                                                }
                                                                target='_blank'>
                                                                <FaLinkedin/>
                                                            </Link>
                                                        </p>
                                                    </li>
                                                )
                                            } </ul>
                                        </div>

                                    </div>
                                )
                            } </div>

                        ))
                    } </div>

                </div>


            </div>
        </div>

    );
};

export default OurTeam;
