import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeNav : 0,
  isNoticePopupActuve: true
}

export const navSlice = createSlice({
  name: 'navState',
  initialState,
  reducers: {
   changeNavState : (state,action)=>{
    state.activeNav = action.payload
   },
   changeNoticePopup : (state,action)=>{
    state.isNoticePopupActuve = action.payload
   }
  },
})

// Action creators are generated for each case reducer function
export const {changeNavState,changeNoticePopup} = navSlice.actions

export default navSlice.reducer