import React from "react";
import { Link } from "react-router-dom";
import "../styles/style.css";
import Button from "./Button";
import ProductsCard from "./ProductsCard";
import { changeNavState } from "../reduxStore/navSlice";
import { useDispatch } from "react-redux";
import { productsListData } from "../utils/productsListData";

const Products = () => {
    const dispatch = useDispatch();

    return (
        <div> {/* Products Section */}
            <div className="w-full flex justify-evenly px-4 lg:px-40 py-5 lg:py-8">
                <div className="w-full flex-col lg:flex-row flex justify-between py-1 lg:py-3">
                    <div className="w-full hidden lg:block flex items-center justify-center lg:w-1/2  pr-[0px] lg:pr-5 pt-[0%] lg:pt-[4.5%]">
                        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-[21px]">
                            {
                                productsListData?.slice(0, 4).map((item, index) => (
                                    <ProductsCard data={item} key={index} />
                                ))
                            }
                        </div>

                    </div>
                    <div className="w-full lg:w-1/2 pl-[0px] lg:pl-[5%] py-4 lg:py-2">
                        <p className="text-start pb-2">04/ Products</p>
                        <h2 className="font-semibold text-[#7B0005] py-5 text-xl lg:text-4xl">
                            OUR FLAGSHIP PRODUCT
                        </h2>
                        <p style={
                            { lineHeight: "29px" }
                        }
                            className="pb-8">
                            Our industry-leading solution was the result of years of diligent development. The
                            steadfast dedication to achieving high standards guarantees a smooth user
                            experience and effectively caters to the unique requirements of customers. This
                            flagship product establishes a benchmark, surpassing anticipated outcomes and
                            demonstrating our commitment to creating outstanding solutions.
                        </p>
                        {/* Products Button */}
                        <div className="w-max" onClick={() => dispatch(changeNavState('products'))}>
                            <Link to={'/Products'}>
                                <Button btnName=" View All " />
                            </Link>
                        </div>

                    </div>
                    <div className="w-full block lg:hidden flex items-center justify-center lg:w-1/2  pr-[0px] lg:pr-5 pt-[0%] lg:pt-[4.5%]">
                        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-[21px]">
                            {
                                productsListData?.slice(0, 4).map((item, index) => (
                                    <ProductsCard data={item} key={index} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Products;
