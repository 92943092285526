import React from "react";
import {MetaDecorator, PageTopComponent} from "../components";
import {aiImg} from "../assets";
import { aiList } from "../utils/serviceList";

const GenerativeAi = ({homeInfoData}) => {

    return (
        <div>
                  <MetaDecorator      
        title="Generative AI"
        titleA="Generative AI"
        keywords="Generative AI"
        description="Generative AI"  
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
           
            <PageTopComponent image={aiImg}
                title="Generative AI Solution"
                subtitle="At Onviro Tech, we excel in providing personalized generative AI solutions that elevate various aspects such as content creation, product design, decision automation, fraud detection, customer service, and tailored experiences."
                description="At Onviro Tech, we offer tailored solutions to meet your specific needs, harnessing the power of Generative AI. Our expertise lies in creating one-of-a-kind content, enhancing product design, automating decision-making and fraud detection, elevating customer service, and delivering personalized customer experiences. Our comprehensive range of services includes consulting, algorithm development, training, and support, all customized to leverage the distinctive qualities of your data."/> {/*...Projects....*/}
            <div className="py-[51px] px-[20px] lg:px-40">

                <div className="w-full pb-8 px-[0px] lg:px-[21%] mt-8">
                    <p className="text-red text-center lg:text-start font-bold text-3xl pb-5">
                        Challenges solved by Generative AI
                    </p>
                    <div className="w-full flex flex-col rounded-[5px] border border-red py-8 px-8">

                        <ul className="pl-5"
                            style={
                                {
                                    listStyle: "number",
                                    lineHeight: "37px"
                                }
                        }>
                            <li>The customer acquisition and retention expenses are high, while the capacity to personalize the customer experience is limited.</li>
                            <li>Consistently producing high-quality content is a time-consuming task that presents challenges in terms of scalability.</li>
                            <li>The processes and workflows in place are ineffective, and there is a lack of sufficient risk management and prevention strategies</li>
                            <li>Creators often face challenges in generating fresh ideas and improving their writing skills, particularly when they encounter a lack of inspiration within their creative domain.</li>
                            <li>When creators find themselves in a creative domain with a dearth of inspiration, they often confront hurdles in generating novel ideas and enhancing their writing abilities.</li>
                        </ul>

                    </div>
                </div>
           
            </div>
            <p className="text-center pt-1 pb-5">Our Process</p>
                <p className="font-semibold text-[#7B0005] text-xl lg:text-4xl text-center pb-3">
                Some Easy Steps to Process
                        </p>
            <div className="w-full px-[11px] flex justify-evenly px-4 lg:px-40 py-5 lg:py-8">    
                <div className="w-full py-3 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-[25px] ">
                        {aiList?.slice(0,6).map((item, index) => (  
                                <div className={`${index % 2 === 0 ? 'bg-[#7B0005]' : 'bg-[#371E2B]'} px-[25px] py-[21px] text-[#fff] rounded relative mt-5`} key={index}>
                                    <div className="flex justify-center">
                                    <div className={`w-[60px] h-[60px] ${index % 2 === 0 ? 'bg-[#371E2B]' : 'bg-[#7B0005]'} rounded-full flex items-center justify-center absolute top-[-39px] border-4 border-[#fff]`}><p className="font-bold">{item?.id}</p></div>
                                    </div>
                                     <h2 className="font-semibold pt-3 text-[21px]">{item?.title}</h2>
                                    <p className="pt-3">{item?.description}</p>
                                  
                                </div>
                               ))}
                              
                            </div>
                      
                </div>
                <div className="w-full pb-8 px-[0px] lg:px-40 mt-8">
                    <p className="text-red lg:text-start font-bold text-3xl pb-5">
                    Benefits
                    </p>
                    <div className="w-full flex flex-col rounded-[5px] pb-8">
                  
         <ul className="pl-5"
                            style={
                                {
                                    listStyle: "number",
                                    lineHeight: "37px"
                                }
                        }>
                            <li><strong>Increased efficiency:</strong> Businesses can optimize their content production by leveraging Generative AI solutions like ChatGPT, enabling them to generate high-quality content with greater efficiency, resulting in significant time and resource savings.</li>
                            <li><strong>Personalization:</strong> With Generative AI solutions such as ChatGPT, businesses can generate personalized content that deeply resonates with customers, leading to enhanced engagement and strengthened customer loyalty.</li>
                            <li><strong>Scalability:</strong> By leveraging Generative AI solutions like ChatGPT, businesses can achieve scalable content production without compromising on quality or personalization.</li>
                            <li><strong>Cost-effectiveness:</strong> Through the automation of content creation processes, businesses can effectively mitigate costs linked to manual content production.</li>
                            <li><strong>Improved productivity:</strong> Generative AI solutions like ChatGPT can liberate employees' time, enabling them to prioritize higher-value tasks that necessitate human expertise and creativity.</li>
                            <li><strong>Competitive advantage:</strong> By harnessing the power of Generative AI solutions like ChatGPT, businesses can generate distinct and captivating content that sets them apart from the competition.</li>
                        </ul>
                    </div>
                </div>

                <div className="w-full pb-8 px-[0px] lg:px-40 mt-8">
                    <p className="text-red lg:text-start font-bold text-3xl pb-5">
                   Results
                    </p>
                    <div className="w-full flex flex-col rounded-[5px] pb-8">

                        <ul>
                            <li><strong>Increased productivity</strong> <br/>
                            <div className="w-full h-[20px] bg-[#e9ecef] rounded my-5">
                                <div className="w-[50%] h-[20px] bg-[#7B0005] rounded text-center text-white text-[14px]"><p>50%</p></div>
                            </div>
                            </li>
                            <li><strong>Higher customer engagement</strong> <br/>
                            <div className="w-full h-[20px] bg-[#e9ecef] rounded my-5">
                                <div className="w-[42%] h-[20px] bg-[#371E2B] rounded text-center text-white text-[14px]"><p>42%</p></div>
                            </div>
                            </li>
                            <li><strong>Cost savings annually</strong> <br/>
                            <div className="w-full h-[20px] bg-[#e9ecef] rounded my-5">
                                <div className="w-[35%] h-[20px] bg-[#7B0005] rounded text-center text-white text-[14px]"><p>35%</p></div>
                            </div>
                            </li>
                            <li><strong>Faster time-to-market</strong> <br/>
                            <div className="w-full h-[20px] bg-[#e9ecef] rounded my-5">
                                <div className="w-[70%] h-[20px] bg-[#371E2B] rounded text-center text-white text-[14px]"><p>70%</p></div>
                            </div>
                            </li>
                            <li><strong>Improved customer satisfaction</strong> <br/>
                            <div className="w-full h-[20px] bg-[#e9ecef] rounded my-5">
                                <div className="w-[60%] h-[20px] bg-[#7B0005] rounded text-center text-white text-[14px]"><p>60%</p></div>
                            </div>
                            </li>
                           
                        </ul>

                    </div>
                </div>
           
        </div>

    );
};

export default GenerativeAi;
