import React, {useRef} from 'react';
import "../styles/style.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import { ecommerceProjects } from '../utils/ecommerceProjects';
import ProjectCard from './ProjectCard';
import LogoDesignCard from './LogoDesignCard';

const ProjectsSlider = ({data}) => {
    
    const sliderRef = useRef(null);
    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 480,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    };

    const goToPreviousSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const goToNextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    return (
        <div className="relative">
            <Slider {...settings}
                ref={sliderRef}>

         
               
                {
                                data ?. length > 0 && data.map((item, index) => (
                                    <div className='px-[11px]'key={index}>                                  
                                        {
                                        item.category.id === 6 ? (
                                            <LogoDesignCard logo={
                                                    item?.image
                                                }
                                                logoName={
                                                    item?.title
                                                }/>
                                        ) : (
                                            <ProjectCard companyName={
                                                    item ?. title
                                                }
                                                logo={
                                                    item ?. image
                                                }
                                                link={item?.url}
                                                />
                                        )
                                    }
                                           </div>
                                ))
                            }
             
            

            </Slider>
            <button className="absolute left-0 lg:left-[-55px] top-1/2 transform -translate-y-1/2 text-white transition duration-500 bg-[#fff] hover:bg-[#E7DADA] rounded-full border border-black w-10 h-10 flex items-center justify-center"
                onClick={goToPreviousSlide}>
                <FontAwesomeIcon className='text-[#000]'
                    icon={faChevronLeft}/>
            </button>
            <button className="absolute right-0 lg:right-[-55px] top-1/2 transform -translate-y-1/2 text-white transition duration-500 bg-[#fff] hover:bg-[#E7DADA] rounded-full border border-black w-10 h-10 flex items-center justify-center"
                onClick={goToNextSlide}>
                <FontAwesomeIcon className='text-[#000]'
                    icon={faChevronRight}/>
            </button>
        </div>
    );
};

export default ProjectsSlider;
