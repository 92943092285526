import React, {useEffect, useState} from "react";
import ProjectsSlider from "./ProjectsSlider";
import "../styles/style.css";
import { Link } from "react-router-dom";
import axiosBaseURL from "../baseUrl";

const Projects = () => {
    const [active, setActive] = useState(0);

{/* Projects API */} 
const [tab, setTab] = useState([])
const [projectData, setProjectData] = useState(null);

useEffect(() => {
  axiosBaseURL.get("api/category/list", {                     
  }).then((res) => {
    setTab(res.data.category);
 
  })
  .catch((err) => console.log(err));
  
  axiosBaseURL.get(`api/category/8`, {                     
}).then((res) => {
  setProjectData(res.data.projects);
})
.catch((err) => console.log(err));
}, []);

const handleClick = async(index,id)=>{
    setActive(index);

    axiosBaseURL.get(`api/category/${id}`, {                     
    }).then((res) => {
        console.log(res)
      setProjectData(res.data.projects);
    })
    .catch((err) => console.log(err));
}

    return (
        <div> {/* Projects Tab Section */}
                <div className="w-full flex flex-col justify-evenly px-4 lg:px-40 py-3 lg:py-8">
                    <p className="text-center pt-1">03/ Projects</p>
                    <div className="w-full grid grid-cols-1 justify-self-auto py-1 lg:pt-3">
                        <div className="py-2 lg:pt-3 text-center">
                            <h2 className="font-semibold text-[#7B0005] text-xl lg:text-3xl">
                                Check out latest projects by us
                            </h2>
                        </div> 
                        <div className="flex justify-center py-5">
                            
                                    <ul className="flex gap-3 flex-wrap justify-center">
                                    {
                                tab?.map((item,index)=>(
                                    <div key={index}>
                                    <li>
                                        <button className={
                                                `rounded-[5px] px-2 py-2 text-white hover:opacity-90 text-left ${
                                                    active === index && 'bg-[#E7DADA]'
                                                }`
                                            }
                                            onClick={()=>
                                                handleClick(index,item?.id)
                                        }>
                                            <p className={
                                                `w-max pr-[30px] ${
                                                    active === index && 'border-b-2 border-black'
                                                } text-black text-sm`
                                            }>{item?.title}</p>
                                        </button>
                                    </li>
                                    </div>
                               
                                 
                                )) }
                                <li>
                                        <Link to={'/Projects'}>
                                        <button 
                                        onClick={
                                          () => setActive(4)
                                      }
                                        className={
                                            `rounded-[5px] px-2 py-2 text-white hover:opacity-90 text-left ${
                                                active === 4 && 'bg-[#E7DADA]'
                                            }`
                                        }>
                                            <p className={
                                                `w-max pr-[30px] ${
                                                    active === 4 && 'border-b-2 border-black'
                                                } text-black text-sm`
                                            }>View all</p>
                                        </button>
                                        </Link>
                                    </li>
                                </ul>
                           
                           
                        </div>
                    </div>
                    {/* ProjectsSlider Section */}
                    {
                    projectData?.length > 0 && (
                        <div className="w-full mt-0 lg:my-5">
                            <ProjectsSlider data={projectData} />
                        </div>
                    )
                }
 </div>
            </div>
      
    );
};

export default Projects;
