import React from 'react'
import { DropCV, MetaDecorator } from '../components'

const PreferredJobPosition = ({homeInfoData}) => {
  return (
    <>
               <MetaDecorator      
        title="CV"
        titleA="CV"
        keywords="CV"
        description="CV"
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
     
   
    <div className='flex overflow-x-hidden flex-col items-center gap-[20px] p-[11px] lg:p-[40px]'>
        <p className='text-xl text-red font-bold underline underline-red underline-offset-8 decoration-2'>
            Drop CV for Preferred Position
        </p>
        <div className='w-full lg:w-[600px]'>
        <DropCV />
        </div>

    </div>
    </>
  )
}

export default PreferredJobPosition