import React, { useEffect, useState } from "react";
import { MetaDecorator, PageTopComponent } from "../components";
import axiosBaseURL from "../baseUrl";

const IndividualServices = ({homeInfoData}) => {
  const [domainData, setDomainData] = useState([])

  useEffect(() => {
    axiosBaseURL.get(`api/service/domain/details`).then((res) => {
        setDomainData(res.data);
    }).catch((err) => console.log(err));
}, []);

  return (
    <div>
       <MetaDecorator      
        title="Domain Registration"
        titleA={domainData?.meta_title ? domainData?.meta_title : ""}
        keywords={domainData?.meta_keywords ? domainData?.meta_keywords : ""}
        description={domainData?.meta_description ? domainData?.meta_description : ""}
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
         
      <PageTopComponent
        image={domainData?.image}
        title={domainData?.title}
        subtitle={domainData?.summary}
        description={domainData?.description}
      />

      <div className="py-[51px] px-[20px] lg:px-40">
       
          <div className="w-full pb-8 px-[0px] lg:px-[25%] mt-8">
          <div className="w-full flex flex-col rounded-[5px] border border-red py-8 px-8">
    <h2 className="font-bold">Welcome to our Family :) </h2>
    <p className="pt-8">It's feature are:</p>
    <ul className="pl-5" style={{
      listStyle:"number"
    }}>
      <li>Easy to use in control panel</li>
      <li>2 free email accounts</li>
      <li>Unlimited mail forward</li>
      <li>DNS management</li>
      <li>Domain theft protection</li>
    </ul>
    <h2 className="font-bold pt-8">WEB Hosting in Nepal</h2>
    <p>Web hosting is taking a space in a internet so that your site can be spread worldwide. We provide fasted web hosting. We host all type of website. We have both dedicated and shared web hosting services. We provide reliable support, free migrations, secure shell access ,easy upgrade, enhanced security ,lighting fast features.</p>
          </div>
          </div>
          
      </div>
    </div>
  );
};

export default IndividualServices;
