import React from 'react'
import {IoIosArrowForward} from 'react-icons/io';
import { Link } from 'react-router-dom';

const CareerPosition = ({jobTitle,vacancyNumber,slug}) => {
  return (
    <Link to={`/career/${slug}`} className='p-[10px] pb-[7px] flex items-center justify-between border border-b-red border-t-0 border-l-0 border-r-0'>
        <p className='text-dark'>
            {jobTitle}({vacancyNumber})
        </p>
        <IoIosArrowForward />
    </Link>
  )
}

export default CareerPosition