import React from "react";
import { Link } from "react-router-dom";

const ProjectCard = ({ logo, companyName, link }) => {
  return (
    <div className="group hover:bg-backgroundColor px-[10px] py-[10px] flex flex-col items-center justify-evenly gap-[10px] text-center h-[300px] border border-purple rounded-[5px] ">
      <img src={logo} className="w-[200px] h-[100px] object-contain" alt="" />
      <p className="text-dark font-bold text-lg">{companyName}</p>
      <Link to={link} target="_blank">
        <button className=" bg-transparent group-hover:bg-red group-hover:text-white text-[14px] rounded-[5px] px-3 pt-2 pb-3 underline underline-offset-8">View Project</button>
      </Link>
    </div>
  );
};

export default ProjectCard;
