import React from "react";

const Table = ({ data, bgColor }) => {
 
  return (
    <div className={`w-full ${bgColor ? bgColor : "bg-backgroundColor"}`}>
      {data?.length > 0 &&
        data.map((item, index) => (
          <div
            key={index}
            className="w-full px-[10px] py-[15px] flex items-center gap-[20px] border border-b-red border-t-0 border-l-0 border-r-0"
          >
            <p className="w-[60%] text-red font-medium">
              {item?.title ? item.title : ""}
            </p>
            <div
              key={index}
              className="w-[40%] text-[15px] flex flex-col gap-[5px]"
            >
              {item?.info?.length > 0 &&
                item.info?.map((infoItem, index) => (
                  <div key={index} className="w-full">
                    <p className="tex-start font-bold py-[5px]">
                      {infoItem.title ? infoItem.title : ""}
                    </p>
                    {infoItem.data.length > 0 && (
                      <ul className="list-disc flex flex-col gap-[5px]">
                        {infoItem.data.map((data, index) => (
                          <li key={index}>{data}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Table;
