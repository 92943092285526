import React, { useEffect } from "react";
import { AboutUs, HeroSection, MetaDecorator, OurClients, Products, Projects, Services, Testimonials } from "../components";
import "../styles/style.css";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";

const Home = ({homeData,aboutData,homeInfoData}) => {
  const dispatch = useDispatch();

  // Setting the nav state
  useEffect(() => {
    dispatch(changeNavState('home'));
  }, [])
  return (
    <>
 <MetaDecorator      
        title="Onviro Tech - Nepal's Website, Aps Development company, Web Hosting and Domain Registraiton with us."
        titleA={homeInfoData?.meta_title ? homeInfoData?.meta_title : ""}
        keywords={homeInfoData?.meta_keywords ? homeInfoData?.meta_keywords : ""}
        description={homeInfoData?.meta_description ? homeInfoData?.meta_description : ""}
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  
  />
    {/* <Helmet>
    <title>Home</title>
    <meta name="description" content="This is Home Page" />
    <meta name="keywords" content="This is Home Page" />
   
  </Helmet>  */}

    <div className="w-full">
      <div>
      <HeroSection homeData={homeData} />
      </div>
      <div>
      <Services homeData={homeData}/>
      </div>
    <div>
    <AboutUs aboutData={aboutData?.data}/>
    </div>
    <div>
    <Projects />
    </div>
    <div>
    <OurClients OurClientsData={homeData?.brands}/>
    </div>
    <div>
    <Products />
    </div>
    <div>
    <Testimonials testimonialsData={homeData?.testimonials}/>
    </div>
    </div>
    </>
  );
};

export default Home;
