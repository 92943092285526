import React, { useEffect, useState } from "react";
import {
  BackgroundOverlay,
  ImageView,
  MetaDecorator,
  PageTopComponent,
  Table,
} from "../components";
import { JfeaturesImg, eTender1, etender2, jwelleryFeature } from "../assets";
import { changeNavState } from "../reduxStore/navSlice";
import { useDispatch } from "react-redux";
import {
  systemRequirementsA,
  systemRequirementsB,
  systemRequirementsC,
  technologyUsedA,
} from "../utils/bloodBankData";
import { Link } from "react-router-dom";

const Jewellery = ({ homeInfoData }) => {
  const dispatch = useDispatch();

  // Setting the nav state
  useEffect(() => {
    dispatch(changeNavState("products"));
  }, []);

  const [imageToDisplay, setImageToDisplay] = useState("");

  return (
    <div>
      <MetaDecorator
        title="J-Jewellery Software"
        titleA="J-Jewellery Software"
        keywords="J-Jewellery Software"
        description="We are glad to inform you that JJewellery (A Complete Jewellery Management System) is now accessible to assist jewelry stores in managing their business. Anyone with computer skills and a basic knowledge of jewelry stores may start using JJewellery in less than a day. J-Jewellery is ready to offer first-rate customer service at a fair price."
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription="We are glad to inform you that JJewellery (A Complete Jewellery Management System) is now accessible to assist jewelry stores in managing their business. Anyone with computer skills and a basic knowledge of jewelry stores may start using JJewellery in less than a day. J-Jewellery is ready to offer first-rate customer service at a fair price."
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
      />

      <PageTopComponent
        image={jwelleryFeature}
        title="J-Jewellery Software"
        subtitle="J-Jewellery Software for Jewellery shops"
        description="We are glad to inform you that JJewellery (A Complete Jewellery Management System) is now accessible to assist jewelry stores in managing their business. Anyone with computer skills and a basic knowledge of jewelry stores may start using JJewellery in less than a day. J-Jewellery is ready to offer first-rate customer service at a fair price."
        btnName="Buy Jewellery Software"
        btnLink="/contactus"
      />

      <div className="py-[70px] px-[20px] lg:px-40 flex flex-col gap-[90px]">
        {/*....Technology used....*/}
        <div>
          {/* <p className="text-red font-bold text-3xl pb-4">
            Technology used to develop
          </p> */}
          <div className="w-full flex justify-center gap-[50px]">
            <div className="hidden lg:flex w-max h-[500px] shadow">
              <img
                src={JfeaturesImg}
                onClick={() => setImageToDisplay(JfeaturesImg)}
                className="w-full h-full object-contain cursor-pointer"
                alt=""
              />
            </div>
            {/*...Image Display mode...*/}
            {imageToDisplay && (
              <div>
                <ImageView
                  image={imageToDisplay}
                  handleCross={() => setImageToDisplay("")}
                />

                <BackgroundOverlay />
              </div>
            )}

            {/* <div className="w-full lg:w-[40%]">
              <Table data={technologyUsedA} bgColor="bg-white" />
            </div> */}
          </div>
        </div>

        {/*...System Requirements...*/}
        <div>
          {/* <p className="text-red font-bold text-3xl">
            What's procurement software all about?
          </p>
          <p className="pt-3 pb-5">
            e-Tender procurement software is business software that lets
            organizations completely automate their source-to-pay cycle. Users
            can manage every aspect of the procurement process, from budget
            analysis and management to contract management and invoice payment.
            You can use e-Tender procurement software in various ways to
            Purchase any items, Construction works giving your teams better
            visibility and control. All in all, e-Tender procurement software
            can be a fantastic tool for businesses keen on automating the
            processes of buying materials and maintaining a stockpile of goods.
          </p> */}
          <p className="text-red font-bold text-3xl">
           Feature listings
          </p>

          <div className="w-full flex gap-[50px] justify-between flex-wrap mt-[40px] ">
            <div className="w-full flex flex-col gap-[10px]">
              <div>
                <p className="w-[70px] h-[70px] rounded-full flex justify-center items-center border border-red text-red font-bold text-xl">
                  {1}
                </p>
              </div>

              <Table data={systemRequirementsC} />
            </div>
          </div>
        </div>

   
      </div>
    </div>
  );
};

export default Jewellery;
