import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../styles/style.css";
import "../styles/responsive.css";
import axiosBaseURL from '../baseUrl';

const SecondAboutPageSlider = () => {
  {/* About Page Odd Slider API */} 
  const [aboutGallery, setAboutGallery] = useState(null);  

  useEffect(() => {
    axiosBaseURL.get("api/gallery/list", {                     
    }).then((res) => {
      setAboutGallery(res.data.data);
   
    })
    .catch((err) => console.log(err));
  }, []);

  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 3000,
    adaptiveHeight:true,
    centerMode:true,
    centerPadding: 201,
    speed: 7000,
    ease: 'ease',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode:true,
          centerPadding: 201,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode:true,
          centerPadding: 201,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode:true,
          centerPadding:'51px',
        },
      },
    ],
  
  };

  return (
    <div className="overflow-x-hidden mb-5 relative">
      <Slider {...settings} ref={sliderRef}>
      {
          (aboutGallery?.length > 0) && aboutGallery.filter((item,index)=> index % 2 === 0).map((item,index) => (
            <div className='px-[11px] sliderImgHeight' key={index}>
            <img src={item?.image} alt="AboutImg" className="object-cover" />
            </div>
          ))
        }
             
      </Slider>
    </div>
  );
};

export default SecondAboutPageSlider;
