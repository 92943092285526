import React from "react";
import { Link } from "react-router-dom";
import "../styles/style.css";
import "../styles/responsive.css";
import Button from "./Button";

const HeroSection = ({ homeData }) => {
  const reviewsData = homeData?.counter;
  const bannerImg = homeData?.banners;  

  return (
    <div>
      {/* Hero Section */}
      <div className="w-full">
        <div className="w-full flex flex-col lg:flex-row items-center justify-evenly px-4 lg:px-32 py-5 lg:py-2">

          <div className="w-full flex flex-col items-center lg:items-start lg:w-[40%] lg:pr-[5%] text-center lg:text-start heroMain">
            <h1 style={{ animation: "bounce2 2s ease 2" }} className="font-bold text-[#7B0005] text-5xl lg:text-7xl leading-loose heroH2">
              You Dream, <br />We Deliver.
            </h1>
            {
                bannerImg?.slice(0, 1).map((item, index) => (
             <p style={{ lineHeight: "29px" }} key={index} className="pt-[7%] pb-[6%]" dangerouslySetInnerHTML={{ __html: item?.summary }} />
              
          
                ))}
            
            {/* Hero Button */}
            <div className="w-max">
              <Link to={'/contactus'}>
                <Button btnName="Get Started" />
              </Link>
            </div>
          </div>

          <div className="w-full lg:w-[60%] pl-[2%] pt-[3%] pb-[2%]">
            {/* Hero Image */}
            <div className="w-full flex flex-col">
              {
                bannerImg?.slice(0, 1).map((item, index) => (
                  <img
                    src={item?.image}
                    className="w-full h-48 lg:h-[67vh] py-3 object-cover"
                    alt="bannerImg"
                    key={index}
                  />
                ))
              }
            </div>
            {/* Hero Reviews */}
            <div className="w-full flex flex-col lg:flex-row lg:gap-2"> 
            <div className="w-full lg:w-[50%] flex flex-col lg:flex-row lg:justify-stretch gap-5 lg:gap-2 mt-5 lg:mt-2">
              <div className="w-full lg:w-[50%] px-5 flex justify-between items-center gap-[20px] flex-wrap py-5 bg-[#7B0005] text-white rounded">
                <h2 className="font-bold text-2xl">{reviewsData?.happy_clients} +</h2>
                <p className="">Happy <br />Clients</p>
              </div>
              <div className="w-full lg:w-[50%] px-5 flex justify-between items-center gap-[20px] flex-wrap py-5 bg-[#7B0005] text-white rounded">
                <h2 className="font-bold text-2xl">{reviewsData?.creative_people} +</h2>
                <p className="">Creative <br />People</p>
              </div>
            </div>
            <div className="w-full lg:w-[50%] flex flex-col lg:flex-row lg:justify-stretch gap-5 lg:gap-2 mt-5 lg:mt-2">
              <div className="w-full lg:w-[50%] px-5 flex justify-between items-center gap-[20px] flex-wrap py-5 bg-[#7B0005] text-white rounded">
                <h2 className="font-bold text-2xl">{reviewsData?.projects} +</h2>
                <p className="">Projects <br />Done</p>
              </div>
              <div className="w-full lg:w-[50%] px-5 flex justify-between items-center gap-[20px] flex-wrap py-5 bg-[#7B0005] text-white rounded">
                <h2 className="font-bold text-2xl">{reviewsData?.years_experience} +</h2>
                <p className="">Years <br />Experience</p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
