import React from "react";
import { Helmet } from "react-helmet";
import { noDataFound } from "../assets";


const NoDataFound = () => {

  return (
    <>
      <Helmet>
        <title>Page Not Found !!!</title>
      </Helmet>
      <div className="w-full flex justify-center">
        <img
          src={noDataFound}
          alt="NoDataFound"
          className=" object-contain w-full h-[600px]"
        />
      </div>
    </>
  );
};

export default NoDataFound;
