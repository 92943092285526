import React, { useEffect, useState } from "react";
import { LogoDesignCard, MetaDecorator, PageTopComponent } from "../components";
import axiosBaseURL, { baseUrl } from "../baseUrl";

const LogoDesign = ({homeInfoData}) => {
  const [logoData, setLogoData] = useState([])

  useEffect(() => {
    axiosBaseURL.get(`api/service/logo/details`).then((res) => {
        setLogoData(res.data);
    }).catch((err) => console.log(err));
}, []);
const logoTopData = logoData?.Similar_Service;
  return (
    <div>
           <MetaDecorator      
        title="Software Solutions"
        titleA={logoData?.meta_title ? logoData?.meta_title : ""}
        keywords={logoData?.meta_keywords ? logoData?.meta_keywords : ""}
        description={logoData?.meta_description ? logoData?.meta_description : ""}
        ogtitle={homeInfoData?.og_title ? homeInfoData?.og_title : ""}
        ogdescription={homeInfoData?.og_descritpion ? homeInfoData?.og_descritpion : ""}        
        imageUrl={homeInfoData?.og_image ? homeInfoData?.og_image : ""}
  />
          
      <PageTopComponent
        image={logoData?.image}
        title={logoData?.title}
        subtitle={logoData?.summary}
        description={logoData?.description}
        />    

      {/*...Projects....*/}
      <div className="py-[51px] px-[20px] lg:px-40">
        <p className="text-red text-center lg:text-start font-bold text-3xl">
          Logo Designs by us
        </p>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-[51px] items-center py-[11px] mt-5">
          {logoTopData?.length > 0 &&
            logoTopData.map((item, index) => (
              <LogoDesignCard
                key={index}
                logoName={item?.title}
                logo={baseUrl + item?.image}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default LogoDesign;
