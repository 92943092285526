import React from "react";
import { Link } from "react-router-dom";
import "../styles/style.css";
import { serviceList } from "../utils/serviceList";
import { changeNavState } from "../reduxStore/navSlice";
import { useDispatch } from "react-redux";

const Services = () => {
    const dispatch = useDispatch();
    return (
        <div>
             {/* Services Section */}
         <div className="w-full bg-[#E7DADA] my-8">
                <div className="w-full items-center px-4 lg:px-40 pt-[51px] pb-[35px]">
                <p className="text-center pt-1 pb-5">01/ Services</p>
                <p className="font-semibold text-[#7B0005] text-xl lg:text-4xl text-center pb-3">
                Services we provide
                        </p>
                        <p className="text-center pb-4">With over 1000 clients satisfaction, we provide one of the best service.</p>
                       
                        <div className="w-full py-3 grid grid-cols-1 lg:grid-cols-3 gap-[15px]">
                        {serviceList?.slice(0,6).map((item, index) => (  
                                <div onClick={()=>dispatch(changeNavState('services'))} className={`${index % 2 === 0 ? 'bg-[#7B0005]' : 'bg-[#371E2B]'} px-[35px] py-[21px] text-[#fff] rounded`} key={index}>
                                <Link to={`/services/${item?.slug}`}>
                                    <div className="text-[25px]">
                                   <p>{item?.featureImage}</p>
                                    </div>
                                    <h2 className="font-semibold pt-3 text-[21px]">{item?.title}</h2>
                                    <p className="pt-3 text-sm" dangerouslySetInnerHTML={{ __html: item?.description.length > 180 ? item?.description.slice(0,180) + "..." : item?.description }}></p>
                                    </Link>
                                </div>
                               
                               ))}
                              
                            </div>
                    </div>
                </div>
            </div>

    );
};

export default Services;
