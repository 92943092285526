import React from "react";
import Button from "./Button";

const PageTopComponent = ({
    title,
    subtitle,
    description,
    image,
    btnName,
    btnLink
}) => {
    return (
        <div> {/*...Top Section....*/}

            <div className="w-full flex justify-between items-center gap-[90px] bg-backgroundColor px-[11px] lg:px-40 pt-[50px] ">

                {/* <div className="w-full flex justify-between items-center gap-[90px] bg-backgroundColor px-[20px] lg:px-globalPadding py-[50px] "> */}

                {/*...Left Section...*/}
                <div className="w-full lg:w-[50%] flex flex-col mb-[50px] ">
                    <p className="w-full lg:max-w-[70%] font-bold text-[30px] lg:text-titleSize text-red">
                        {title}</p>
                    <p className="text-red font-bold pb-[11px]">
                        {subtitle}</p>
                    <div style={
                            {lineHeight: "29px"}
                        }
                        className="rich-content"
                        dangerouslySetInnerHTML={
                            {__html: description}
                    } />
                    {
                    btnName && (
                        <div className="w-max mt-4">
                           
                                <Button btnName={btnName} link={btnLink} />
                
                        </div>
                    )
                } </div>
                {/*...Right Section...*/}
                <div className="hidden lg:flex w-[40%] h-[570px]">
                    <img src={image}
                        className="w-full h-full object-cover"
                        alt=""/>
                </div>
            </div>
        </div>
    );
};

export default PageTopComponent;
